"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
require("core-js/modules/es.array.reduce.js");
require("core-js/modules/web.url-search-params.delete.js");
require("core-js/modules/web.url-search-params.has.js");
require("core-js/modules/web.url-search-params.size.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _validate = require("@/utils/validate");
var _index = _interopRequireDefault(require("@/components/LangSelect/index.vue"));
var _admins = require("@/api/admins");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.centerDialogVisible = false;
    this.validateUsername = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error("Please enter valid email"));
      } else {
        callback();
      }
    };
    this.validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("The password can not be less than 6 digits"));
      } else {
        callback();
      }
    };
    this.validateEmail = (rule, value, callback) => {
      if (!(0, _validate.isValidEmail)(value)) {
        callback(new Error("Please enter valid email"));
      } else {
        callback();
      }
    };
    this.loginForm = {
      email: "",
      password: ""
    };
    this.resetForm = {
      email: ""
    };
    this.changePasswordForm = {
      password: "",
      confirmPassword: "",
      userId: 0,
      sessionId: ""
    };
    this.loginRules = {
      email: [{
        validator: this.validateUsername,
        trigger: "blur"
      }],
      password: [{
        validator: this.validatePassword,
        trigger: "blur"
      }]
    };
    this.resetRules = {
      email: [{
        validator: this.validateEmail,
        trigger: "blur"
      }]
    };
    this.changePassRules = {
      password: [{
        validator: this.validatePassword,
        trigger: "blur",
        message: "Password is required"
      }],
      confirmPassword: [{
        validator: this.validatePassword,
        trigger: "blur",
        message: "Confirm password is required"
      }]
    };
    this.passwordType = "password";
    this.confirmPasswordType = "password";
    this.resetloading = false;
    this.showDialog = false;
    this.changePasswordDialog = false;
    this.changePasswordloading = false;
    this.loading = false;
    this.otherQuery = {};
  }
  created() {
    debugger;
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("sessionId");
    const id = urlParams.get("id");
    if (code != null && id != null) {
      this.changePasswordForm.userId = parseInt(id);
      this.changePasswordForm.sessionId = code;
      this.checkLink();
    }
  }
  async checkLink() {
    try {
      await (0, _admins.changePasswordLink)(this.changePasswordForm).then(res => {
        if (res.responseCode == 300 && _admin.AdminModule.id == null) {
          this.$message({
            message: "Oops! Link expired",
            type: "error"
          });
        } else if (res.responseCode == 200) {
          this.changePasswordDialog = true;
        } else {}
      });
    } catch (error) {
      this.$message(error.message);
    }
  }
  onRouteChange(route) {
    // TODO: remove the "as Dictionary<string>" hack after v4 release for vue-router
    // See https://github.com/vuejs/vue-router/pull/2050 for details
    const query = route.query;
    if (query) {
      this.redirect = query.redirect;
      this.otherQuery = this.getOtherQuery(query);
    }
  }
  mounted() {
    if (this.loginForm.email === "") {
      this.$refs.email.focus();
    } else if (this.loginForm.password === "") {
      this.$refs.password.focus();
    }
  }
  async changePassword() {
    this.$refs.changePasswordForm.validate(async valid => {
      if (this.changePasswordForm.password === this.changePasswordForm.confirmPassword) {
        if (valid) {
          this.changePasswordloading = true;
          try {
            await (0, _admins.changePassword)(this.changePasswordForm);
            this.$notify({
              title: "Success",
              message: "Password changed successfully",
              type: "success",
              duration: 3000
            });
            this.changePasswordloading = false;
            this.changePasswordDialog = false;
          } catch (error) {
            this.changePasswordloading = false;
            this.$message(error.message);
          }
        } else {
          this.$message({
            message: "Enter all details",
            type: "error"
          });
          return false;
        }
      } else {
        this.$message({
          message: "Password doesn't match",
          type: "error"
        });
        return false;
      }
    });
  }
  async forgotPassword() {
    this.$refs.resetForm.validate(async valid => {
      if (valid) {
        this.resetloading = true;
        try {
          var data = await (0, _admins.forgotPassword)(this.resetForm);
          if (data.responseCode == 200) {
            this.$notify({
              title: "Success",
              message: "Reset link has been sent to your mail",
              type: "success",
              duration: 3000
            });
            this.centerDialogVisible = false;
            this.resetForm.email = "";
          } else {
            this.$message({
              message: "Email does not exists",
              type: "error"
            });
          }
          this.resetloading = false;
        } catch (error) {
          this.resetloading = false;
        }
      } else {
        this.$message({
          message: "Invalid email",
          type: "error"
        });
        return false;
      }
    });
  }
  showPwd() {
    if (this.passwordType === "password") {
      this.passwordType = "";
    } else {
      this.passwordType = "password";
    }
    this.$nextTick(() => {
      this.$refs.password.focus();
    });
  }
  showConfirmPwd() {
    if (this.confirmPasswordType === "password") {
      this.confirmPasswordType = "";
    } else {
      this.confirmPasswordType = "password";
    }
    this.$nextTick(() => {
      this.$refs.confirmPassword.focus();
    });
  }
  handleLogin() {
    this.$refs.loginForm.validate(async valid => {
      if (valid) {
        this.loading = true;
        try {
          await _admin.AdminModule.Login(this.loginForm);
          this.$router.push({
            path: this.redirect || "/",
            query: this.otherQuery
          });
        } catch (error) {
          this.loading = false;
        }
      } else {
        this.$message({
          message: "Invalid email or password",
          type: "error"
        });
        return false;
      }
    });
  }
  getOtherQuery(query) {
    return Object.keys(query).reduce((acc, cur) => {
      if (cur !== "redirect") {
        acc[cur] = query[cur];
      }
      return acc;
    }, {});
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Watch)("$route", {
  immediate: true
})], default_1.prototype, "onRouteChange", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "Login",
  components: {
    LangSelect: _index.default
  }
})], default_1);
var _default = exports.default = default_1;