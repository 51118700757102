"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateProductOptionValue = exports.updateOrder = exports.getProductOptionValues = exports.getProductOptionValueCount = exports.getProductOptionValueById = exports.deleteProductOptionValue = exports.defaultProductOptionValuesData = exports.cartProductOptionValue = exports.addProductOptionValue = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultProductOptionValuesData = exports.defaultProductOptionValuesData = {
  id: 0,
  enabled: true,
  optionValueId: null,
  priority: 0,
  productOptionId: null,
  images: []
};
const getProductOptionValues = params => (0, _request.default)({
  url: '/product-option-values',
  method: 'get',
  params
});
exports.getProductOptionValues = getProductOptionValues;
const getProductOptionValueById = id => (0, _request.default)({
  url: `/product-option-values/${id}`,
  method: 'get'
});
exports.getProductOptionValueById = getProductOptionValueById;
const updateProductOptionValue = (id, data) => (0, _request.default)({
  url: `/product-option-values/${id}`,
  method: 'patch',
  data
});
exports.updateProductOptionValue = updateProductOptionValue;
const deleteProductOptionValue = ProductOptionValueValuesname => (0, _request.default)({
  url: `/product-option-values/${ProductOptionValueValuesname}`,
  method: 'delete'
});
exports.deleteProductOptionValue = deleteProductOptionValue;
const addProductOptionValue = data => (0, _request.default)({
  url: '/product-option-values/',
  method: 'post',
  data
});
exports.addProductOptionValue = addProductOptionValue;
const cartProductOptionValue = data => (0, _request.default)({
  url: '/product-option-values/delete-from-cart',
  method: 'post',
  data
});
exports.cartProductOptionValue = cartProductOptionValue;
const getProductOptionValueCount = params => (0, _request.default)({
  url: '/product-option-values/count',
  method: 'get',
  params
});
exports.getProductOptionValueCount = getProductOptionValueCount;
const updateOrder = data => (0, _request.default)({
  url: '/product-option-values/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;