"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("./../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _order = require("@/api/order");
var _index = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _cart = require("@/api/cart");
// import {
//   getAdminById,
//   defaultAdminData,
//   updateAdmin,
//   register
// } from '@/api/admins'

let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.redirectionType = [{
      id: "0",
      name: "Link"
    }, {
      id: "1",
      name: "Contact Page"
    }, {
      id: "2",
      name: "About Page"
    }];
    this.postForm = Object.assign({}, _order.defaultOrderData);
    this.loading = false;
    this.showImageUpload = false;
    this.image = "https://via.placeholder.com/154";
    this.rules = {
      fullName: [{
        required: true,
        message: "Please enter full name",
        trigger: ["blur", "change"]
      }, {
        min: 3,
        message: "Length should be greater than 3",
        trigger: ["blur", "change"]
      }, {
        pattern: "^[a-zA-Z ]*$",
        message: "Name should contain characters only",
        trigger: ["blur", "change"]
      }],
      email: [
      // {
      //   required: true,
      //   message: 'Please enter email',
      //   trigger: 'blur'
      // },
      {
        type: "email",
        message: "Please enter correct email",
        trigger: ["blur", "change"]
      }],
      pincode: [{
        pattern: "^[1-9][0-9]{5}$",
        message: "Please enter correct pincode",
        trigger: ["blur", "change"]
      }],
      contactNumber: [{
        required: true,
        message: "Please enter contact number",
        trigger: "blur"
      }, {
        trigger: ["blur", "change"],
        pattern: "(^[0-9]*$)",
        message: "Contact number should contain number only"
      }, {
        max: 10,
        min: 10,
        message: "Length should be of 10 Digits",
        trigger: ["blur", "change"]
      }]
    };
    // private imageUpload(file: any) {
    //   this.postForm.image = file.response
    // }
    // private sendToAllToggled() {
    //   if (this.postForm.sendToAll) {
    //     this.$confirm(
    //       'Are you sure want to send notification to All Orders?',
    //       'Attention!',
    //       {
    //         confirmButtonText: "Yes, I know what I'm doing",
    //         confirmButtonClass: 'el-button--success',
    //         cancelButtonText: this.$tc('table.cancel'),
    //         cancelButtonClass: 'el-button--danger',
    //         type: 'error'
    //       }
    //     )
    //       .then(confirm => {
    //         // let it confirm
    //         // clear other dropdowns
    //       })
    //       .catch(_ => {
    //         this.postForm.sendToAll = false
    //       })
    //   }
    // }
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  handleDelete(index) {
    this.$confirm("This will permanently delete the file. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning"
    }).then(() => {
      if (index <= this.postForm.addToCart.length) {
        this.postForm.addToCart.splice(index, 1);
        this.postForm.addToCartIds.splice(index, 1);
        (0, _order.updateOrder)(this.postForm.id, this.postForm).then(() => {
          this.$message({
            type: "success",
            message: "Delete successfully"
          });
          this.updateOrderData(Number(this.$route.params.id));
        });
      }
    }).catch(() => {
      this.$message({
        type: "info",
        message: "Delete cancelled"
      });
    });
  }
  async changeQuantity(cart) {
    if (cart) {
      (0, _cart.updateCart)(cart.id, cart).then(res => {
        this.updateOrderData(cart.orderId);
      }).catch(err => {
        this.$message({
          message: "Error Occured while updating quantity",
          type: "warning"
        });
      });
    }
  }
  async updateOrderData(id) {
    this.totalAMT = 0;
    let products;
    let orderData = await (0, _order.getOrderById)(id);
    // products = orderData.product.addToCart;
    // products.forEach((product: any) => {
    //   if (product.product.isSale == true) {
    //     this.totalAMT += product.quantity * product.price;
    //   } else {
    //     this.totalAMT += product.quantity * product.price;
    //   }
    // });
    await (0, _order.updateOrder)(id, orderData.product).then(res => {
      this.$message({
        message: "Quantity updated",
        type: "success"
      });
    });
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _order.defaultOrderData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _order.getOrderById)(id);
      this.postForm = data.product;
      this.image = data.image;
    } catch (err) {
      Promise.reject(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.image = "https://via.placeholder.com/154";
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _order.updateOrder)(this.postForm.id, this.postForm);
      } else {
        await (0, _order.addOrder)(this.postForm);
      }
      this.$notify({
        title: "Success",
        message: "Data saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/Order/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "TypeDetail",
  components: {
    PanThumb: _index.default,
    AvatarUpload: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;