"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const orderRouter = {
  path: '/order',
  component: _index.default,
  redirect: 'noredirect',
  name: 'orderManagement.title',
  meta: {
    roles: ['superadmin', 'manager', 'backOffice'],
    title: 'orderManagement.title',
    icon: 'shopping-cart'
  },
  children: [{
    path: 'list/',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/list.vue'))),
    name: 'orderManagement.order.manage',
    meta: {
      title: 'orderManagement.title',
      noCache: true,
      icon: 'shopping-cart'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'))),
    name: 'orderManagement.order.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'orderManagement.order.manage',
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: 'add/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/manage.vue'))),
    name: 'orderManagement.order.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'orderManagement.order.manage',
      noCache: true,
      activeMenu: '/order/list',
      hidden: true
    }
  }, {
    path: 'orderPdf/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/order/order.vue'))),
    name: 'orderPdf',
    meta: {
      title: 'orderManagement.title',
      hidden: true,
      noCache: true,
      icon: 'shopping-cart'
    }
  }]
};
var _default = exports.default = orderRouter;