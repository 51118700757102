"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const CategoryRouter = {
  path: '/category',
  component: _index.default,
  redirect: 'noredirect',
  name: 'categoryManagement.title',
  meta: {
    title: 'categoryManagement.title',
    icon: 'create-dashboard'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/list.vue'))),
    name: 'categoryManagement.category.manage',
    meta: {
      title: 'categoryManagement.title',
      noCache: true,
      icon: 'create-dashboard',
      header: 'List of all categories',
      info: 'All category details are available here. You can search categories by applying filters on fields like category name. After clicking on category name you will be redirected to style, where all the style details are available'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/manage.vue'))),
    name: 'categoryManagement.category.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'categoryManagement.category.manage',
      noCache: true,
      activeMenu: '/category/list',
      hidden: true,
      header: 'Edit category',
      info: 'You can edit category here'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/category/manage.vue'))),
    name: 'categoryManagement.category.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'categoryManagement.category.manage',
      noCache: true,
      activeMenu: '/category/list',
      hidden: true,
      header: 'Add category',
      info: 'You can create category here'
    }
  }]
};
var _default = exports.default = CategoryRouter;