"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _interopRequireWildcard = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AdminModule = void 0;
require("core-js/modules/es.error.cause.js");
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _admins = require("@/api/admins");
var _cookies = require("@/utils/cookies");
var _router = _interopRequireWildcard(require("@/router"));
var _permission = require("./permission");
var _tagsView = require("./tags-view");
var _store = _interopRequireDefault(require("@/store"));
let Admin = class Admin extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.token = (0, _cookies.getToken)() || '';
    this.name = '';
    this.avatar = '';
    this.roles = [];
    this.email = '';
    this.id = 0;
  }
  SET_TOKEN(token) {
    this.token = token;
  }
  SET_NAME(name) {
    this.name = name;
  }
  SET_AVATAR(avatar) {
    this.avatar = avatar;
  }
  SET_ID(id) {
    this.id = id;
  }
  SET_ROLES(roles) {
    this.roles = roles;
  }
  SET_EMAIL(email) {
    this.email = email;
  }
  async Login(adminInfo) {
    let {
      email,
      password
    } = adminInfo;
    email = email.trim();
    try {
      var data = await (0, _admins.login)({
        email,
        password
      });
      (0, _cookies.setToken)(data.accessToken);
      this.SET_TOKEN(data.accessToken);
      return await (0, _admins.getAdminInfo)({});
    } catch (error) {
      this.ResetToken();
      throw new Error(error);
    }
  }
  ResetToken() {
    (0, _cookies.removeToken)();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }
  async GetAdminInfo() {
    if (this.token === '') {
      throw Error('GetAdminInfo: token is undefined!');
    }
    const data = await (0, _admins.getAdminInfo)({});
    if (!data) {
      throw Error('Verification failed, please Login again.');
    }
    // // roles must be a non-empty array
    // if (!roles || roles.length <= 0) {
    //   throw Error('GetAdminInfo: roles must be a non-null array!')
    // }
    this.SET_ROLES([data.adminsRole.name]);
    this.SET_NAME(data.fullName);
    this.SET_AVATAR(data.avatar ? data.avatar : '');
    this.SET_ID(data.id);
    this.SET_EMAIL(data.email);
    return data;
  }
  async ChangeRoles(role) {
    // Dynamically modify permissions
    const token = role + '-token';
    this.SET_TOKEN(token);
    (0, _cookies.setToken)(token);
    await this.GetAdminInfo();
    (0, _router.resetRouter)();
    // Generate dynamic accessible routes based on roles
    _permission.PermissionModule.GenerateRoutes(this.roles);
    // Add generated routes
    _router.default.addRoutes(_permission.PermissionModule.dynamicRoutes);
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
  }
  async LogOut() {
    if (this.token === '') {
      throw Error('LogOut: token is undefined!');
    }
    await (0, _admins.logout)();
    (0, _cookies.removeToken)();
    (0, _router.resetRouter)();
    // Reset visited views and cached views
    _tagsView.TagsViewModule.delAllViews();
    this.SET_TOKEN('');
    this.SET_ROLES([]);
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_TOKEN", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_NAME", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_AVATAR", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_ID", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_ROLES", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], Admin.prototype, "SET_EMAIL", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "Login", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "ResetToken", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "GetAdminInfo", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "ChangeRoles", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], Admin.prototype, "LogOut", null);
Admin = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'admin'
})], Admin);
const AdminModule = exports.AdminModule = (0, _vuexModuleDecorators.getModule)(Admin);