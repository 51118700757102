"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'icon': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M115.1 0a13 13 0 015 1c1.5.6 2.9 1.5 4 2.7a13.1 13.1 0 012.8 4c.7 1.6 1 3.3 1 5.2v102.3c0 3.6-1.2 6.7-3.5 9.1a12 12 0 01-9 3.6H13c-3.9 0-7-1.2-9.4-3.7a13.2 13.2 0 01-3.5-9.5v-102c0-3.4 1.1-6.3 3.4-8.9A12 12 0 0112.8.1h102.3zM81.4 109c1.8 0 3-.4 3.8-1.2.8-.8 1.2-1.9 1.2-3.3 0-1.2-.4-2.3-1.2-3.2-.8-.8-2-1.3-3.8-1.3h-8.8l.1-.8V27h9c1.8 0 3-.4 3.7-1.3.6-.9 1-2 1-3.2a5 5 0 00-1-3.2c-.7-.9-2-1.3-3.7-1.3H46.3c-1.8 0-3 .4-3.7 1.3-.6.9-1 2-1 3.2a5 5 0 001 3.2c.7.9 2 1.3 3.7 1.3h8.1v72.5l.2.4h-8c-1.8 0-3 .5-3.8 1.3-.8 1-1.2 2-1.2 3.2 0 1.4.4 2.5 1.2 3.3.8.8 2 1.2 3.8 1.2h34.8z"/>'
  }
});