"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _echarts = _interopRequireDefault(require("echarts"));
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueClassComponent = require("vue-class-component");
var _resize = _interopRequireDefault(require("@/components/Charts/mixins/resize"));
var _admins = require("@/api/admins");
let default_1 = class default_1 extends (0, _vueClassComponent.mixins)(_resize.default) {
  created() {}
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }
  async initChart() {
    const data = await (0, _admins.getAdminStats)();
    this.data = data.categories;
    const categoryNames = [];
    await this.data.forEach(category => {
      categoryNames.push(category.name);
    });
    this.chart = _echarts.default.init(this.$el, 'macarons');
    this.chart.setOption({
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      legend: {
        left: 'center',
        bottom: '10',
        data: categoryNames
      },
      series: [{
        name: 'Videos per category',
        type: 'pie',
        roseType: 'radius',
        radius: [15, 95],
        center: ['50%', '38%'],
        data: this.data,
        animationEasing: 'cubicInOut',
        animationDuration: 2600
      }]
    });
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: 'chart'
})], default_1.prototype, "className", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '100%'
})], default_1.prototype, "width", void 0);
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: '300px'
})], default_1.prototype, "height", void 0);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PieChart'
})], default_1);
var _default = exports.default = default_1;