"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateGallery = exports.getGalleryCount = exports.getGalleryById = exports.getGallery = exports.deleteGallery = exports.defaultGalleryData = exports.addGallery = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultGalleryData = exports.defaultGalleryData = {
  id: 0,
  enabled: true,
  createdBy: 0,
  image: '',
  text: ''
};
const getGallery = params => (0, _request.default)({
  url: '/gallery',
  method: 'get',
  params
});
exports.getGallery = getGallery;
const getGalleryById = id => (0, _request.default)({
  url: `/gallery/${id}`,
  method: 'get'
});
exports.getGalleryById = getGalleryById;
const updateGallery = (id, data) => (0, _request.default)({
  url: `/gallery/${id}`,
  method: 'patch',
  data
});
exports.updateGallery = updateGallery;
const deleteGallery = id => (0, _request.default)({
  url: `/gallery/${id}`,
  method: 'delete'
});
exports.deleteGallery = deleteGallery;
const addGallery = data => (0, _request.default)({
  url: '/gallery/',
  method: 'post',
  data
});
exports.addGallery = addGallery;
const getGalleryCount = params => (0, _request.default)({
  url: '/gallery/count',
  method: 'get',
  params
});
exports.getGalleryCount = getGalleryCount;
const updateOrder = data => (0, _request.default)({
  url: '/gallery/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;