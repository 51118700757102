"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const userRouter = {
  path: '/users',
  component: _index.default,
  redirect: 'noredirect',
  name: 'user Management',
  meta: {
    roles: ['superuser', 'user'],
    title: 'userManagement.title',
    icon: 'user'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "users-list" */'@/views/user/list.vue'))),
    name: 'users',
    meta: {
      title: 'userManagement.users.title',
      noCache: true,
      icon: 'user',
      header: 'List of all users',
      info: 'All user details are available here.  You can search user information by applying filters on fields like name, email, mobile number, gender etc. Green dot represents active user. Red dot represents disabled user'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/user/manage.vue'))),
    name: 'Edit user',
    props: {
      isEdit: true
    },
    meta: {
      title: 'userManagement.users.manage',
      noCache: true,
      activeMenu: '/user/list',
      hidden: true,
      header: 'Edit user',
      info: 'We can edit user here, we can also assign role to a particular user'
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/user/manage.vue'))),
    name: 'Add user',
    props: {
      isEdit: false
    },
    meta: {
      title: 'userManagement.users.manage',
      noCache: true,
      activeMenu: '/user/list',
      hidden: true,
      header: 'Add user',
      info: 'We can create user here, we can also assign role to a particular user'
    }
  }]
};
var _default = exports.default = userRouter;