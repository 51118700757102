"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admins = require("@/api/admins");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("./../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _adminsRoles = require("@/api/adminsRoles");
var _utils = require("../../../utils");
var _common = require("@/api/common");
var _index = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _admin = require("../../../store/modules/admin");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.roleList = [];
    this.postForm = Object.assign({}, _admins.defaultAdminData);
    this.myRole = _admin.AdminModule.roles;
    this.loading = false;
    this.image = 'https://via.placeholder.com/300';
    this.showImageUpload = false;
    this.genderList = [{
      label: 'Female',
      value: 'Female'
    }, {
      label: 'Male',
      value: 'Male'
    }, {
      label: 'Others',
      value: 'Others'
    }];
    this.rules = {
      fullName: [{
        required: true,
        message: 'Please enter full name',
        trigger: ['blur', 'change']
      }, {
        min: 3,
        message: 'Length should be greater than 3',
        trigger: ['blur', 'change']
      }, {
        pattern: '^[a-zA-Z ]*$',
        message: 'Name should contain characters only',
        trigger: ['blur', 'change']
      }],
      contactNumber: [{
        required: true,
        message: 'Please enter mobile number',
        trigger: 'blur'
      }, {
        trigger: ['blur', 'change'],
        pattern: '[123456789][0-9]*$',
        message: 'Mobile number should contain number only'
      }, {
        max: 10,
        min: 10,
        message: 'Length should be of 10 Digits',
        trigger: ['blur', 'change']
      }],
      email: [{
        required: true,
        message: 'please enter e-mail',
        trigger: 'blur'
      }, {
        min: 10,
        max: 50,
        message: 'Length should be between 10-50 characters',
        trigger: ['blur', 'change']
      }, {
        type: 'email',
        message: 'Please enter correct email address',
        trigger: ['blur', 'change']
      }],
      password: [{
        required: true,
        message: 'Please enter password',
        trigger: 'blur'
      }, {
        min: 8,
        max: 15,
        message: 'Length should be 8 to 15',
        trigger: ['blur', 'change']
      }],
      adminsRoleId: [{
        required: true,
        message: 'Please select your role',
        trigger: ['blur', 'change']
      }],
      gender: [{
        required: true,
        message: 'Please select gender',
        trigger: ['blur', 'change']
      }]
    };
  }
  toggleShow() {
    this.showImageUpload = !this.showImageUpload;
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  onClose() {
    this.showImageUpload = false;
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.fetchRoles();
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _admins.defaultAdminData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _admins.getAdminById)(id);
      this.postForm = data;
      this.image = data.avatar;
      const title = this.postForm.email;
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  async fetchRoles() {
    try {
      var role = this.myRole[0];
      if (role === 'superadmin') {
        this.roleList = await (0, _adminsRoles.getAdminsRoles)((0, _utils.getQuery)({
          enabled: true,
          sort: 'name,ASC',
          filter: {
            enabled: 'eq'
          }
        }));
      }
      if (role === 'Admin') {
        this.roleList = await (0, _adminsRoles.getAdminsRoles)((0, _utils.getQuery)({
          enabled: true,
          sort: 'name,ASC',
          id: 3,
          filter: {
            enabled: 'eq',
            id: 'eq'
          }
        }));
      }
    } catch (err) {
      Promise.reject(err);
    }
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.avatar = res;
      });
    });
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    if (this.postForm.avatar === '') {
      this.postForm.avatar = this.image;
    }
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.image = 'https://via.placeholder.com/360x250';
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        this.postForm.adminsRole.id = this.postForm.adminsRoleId;
        // delete this.postForm.password
        await (0, _admins.updateAdmin)(this.postForm.id, this.postForm);
      } else {
        if (this.postForm.avatar === '') {
          this.postForm.avatar = this.image;
        }
        delete this.postForm.adminsRole;
        await (0, _admins.register)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Admin saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/admins/list');
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'AdminDetail',
  components: {
    AvatarUpload: _index.default,
    PanThumb: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;