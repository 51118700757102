"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ErrorLogModule = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _store = _interopRequireDefault(require("@/store"));
let ErrorLog = class ErrorLog extends _vuexModuleDecorators.VuexModule {
  constructor() {
    super(...arguments);
    this.logs = [];
  }
  ADD_ERROR_LOG(log) {
    this.logs.push(log);
  }
  CLEAR_ERROR_LOG() {
    this.logs.splice(0);
  }
  AddErrorLog(log) {
    this.ADD_ERROR_LOG(log);
  }
  ClearErrorLog() {
    this.CLEAR_ERROR_LOG();
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], ErrorLog.prototype, "ADD_ERROR_LOG", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Mutation], ErrorLog.prototype, "CLEAR_ERROR_LOG", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], ErrorLog.prototype, "AddErrorLog", null);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], ErrorLog.prototype, "ClearErrorLog", null);
ErrorLog = (0, _tslib.__decorate)([(0, _vuexModuleDecorators.Module)({
  dynamic: true,
  store: _store.default,
  name: 'errorLog'
})], ErrorLog);
const ErrorLogModule = exports.ErrorLogModule = (0, _vuexModuleDecorators.getModule)(ErrorLog);