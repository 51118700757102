// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.box-card-component .el-card__header {\n  padding: 0px !important;\n}\n.panThumb {\n  z-index: 100;\n  height: 70px !important;\n  width: 70px !important;\n  position: absolute !important;\n  top: -45px;\n  left: 0px;\n  border: 5px solid #ffffff;\n  background-color: #fff;\n  margin: auto;\n  box-shadow: none !important;\n}\n.panThumb .pan-info {\n  box-shadow: none !important;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#333333",
	"menuText": "#fff",
	"menuActiveText": "#333333"
};
module.exports = exports;
