"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", [_c("el-row", {
    staticClass: "panel-group",
    attrs: {
      gutter: 40
    }
  }, [_c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/banner/list"
    }
  }, [_c("div", {
    staticClass: "card-panel",
    on: {
      click: function ($event) {
        return _vm.handleSetLineChartData("purchases");
      }
    }
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("unicon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "image",
      height: "50",
      width: "50"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Banners ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.bannerTotal,
      duration: 3200
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/category/list"
    }
  }, [_c("div", {
    staticClass: "card-panel",
    on: {
      click: function ($event) {
        return _vm.handleSetLineChartData("purchases");
      }
    }
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("unicon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "create-dashboard",
      height: "50",
      width: "50"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Categories ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.categoriesCount,
      duration: 3200
    }
  })], 1)])])], 1), _c("el-col", {
    staticClass: "card-panel-col",
    attrs: {
      xs: 12,
      sm: 12,
      lg: 6
    }
  }, [_c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/#"
    }
  }, [_c("div", {
    staticClass: "card-panel"
  }, [_c("div", {
    staticClass: "card-panel-icon-wrapper icon-money"
  }, [_c("unicon", {
    staticClass: "card-panel-icon",
    attrs: {
      name: "cube",
      height: "50",
      width: "50"
    }
  })], 1), _c("div", {
    staticClass: "card-panel-description"
  }, [_c("div", {
    staticClass: "card-panel-text"
  }, [_vm._v(" Products ")]), _c("count-to", {
    staticClass: "card-panel-num",
    attrs: {
      "start-val": 0,
      "end-val": _vm.productCount,
      duration: 3200
    }
  })], 1)])])], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;