"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateStatus = exports.updateOrder = exports.getTopOrders = exports.getOrders = exports.getOrderInfo = exports.getOrderCount = exports.getOrderById = exports.getAllOrders = exports.deleteOrder = exports.defaultOrderData = exports.addOrder = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultOrderData = exports.defaultOrderData = {
  id: 0,
  enabled: true,
  userId: null,
  addToCartIds: null,
  status: 0,
  addToCart: null,
  invoice: null,
  isDeleted: false
};
const getOrders = params => (0, _request.default)({
  url: '/orders',
  method: 'get',
  params
});
exports.getOrders = getOrders;
const getAllOrders = params => (0, _request.default)({
  url: '/orders/all',
  method: 'get',
  params
});
exports.getAllOrders = getAllOrders;
const getTopOrders = () => (0, _request.default)({
  url: '/orders/get-top-user',
  method: 'get'
});
exports.getTopOrders = getTopOrders;
const getOrderById = id => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'get'
});
exports.getOrderById = getOrderById;
const getOrderInfo = data => (0, _request.default)({
  url: '/orders/me',
  method: 'post',
  data
});
exports.getOrderInfo = getOrderInfo;
const updateOrder = (id, data) => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'patch',
  data
});
exports.updateOrder = updateOrder;
const updateStatus = data => (0, _request.default)({
  url: `/orders/update`,
  method: 'put',
  data
});
exports.updateStatus = updateStatus;
const deleteOrder = id => (0, _request.default)({
  url: `/orders/${id}`,
  method: 'delete'
});
exports.deleteOrder = deleteOrder;
const addOrder = data => (0, _request.default)({
  url: '/orders/',
  method: 'post',
  data
});
exports.addOrder = addOrder;
const getOrderCount = () => (0, _request.default)({
  url: '/orders/count',
  method: 'get'
});
exports.getOrderCount = getOrderCount;