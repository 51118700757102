"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const optionRouter = {
  path: '/option',
  component: _index.default,
  redirect: 'noredirect',
  name: 'optionManagement.title',
  meta: {
    roles: ['superadmin', 'admin'],
    title: 'optionManagement.title',
    icon: 'box'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/option/list.vue'))),
    name: 'optionManagement.option.manage',
    meta: {
      title: 'optionManagement.title',
      noCache: true,
      icon: 'box'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/option/manage.vue'))),
    name: 'optionManagement.option.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'optionManagement.option.manage',
      noCache: true,
      activeMenu: '/option/list',
      hidden: true
    }
  }, {
    path: 'add',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/option/manage.vue'))),
    name: 'optionManagement.option.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'optionManagement.option.manage',
      noCache: true,
      activeMenu: '/option/list',
      hidden: true
    }
  }]
};
var _default = exports.default = optionRouter;