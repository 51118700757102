// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n@supports (-webkit-mask: none) and (not (cater-color: #e0e0e0)) {\n.login-container .el-input input {\n    color: #e0e0e0;\n}\n.login-container .el-input input::first-line {\n    color: #505050;\n}\n}\n.login-container {\n  height: 100%;\n  width: 100%;\n  overflow: hidden;\n  background-color: white;\n  background-size: cover;\n  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.1);\n}\n.login-container .boxed {\n  width: 80%;\n  margin: 10em auto;\n  margin-bottom: 0px;\n}\n.login-container .boxed .login-logo {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.login-container .login-form {\n  position: relative;\n  max-width: 100%;\n  margin: 0 auto;\n  overflow: hidden;\n  background: #fff;\n  padding: 3em 2em 1em;\n  border-radius: 1em;\n}\n.login-container .el-input {\n  display: inline-block;\n  height: 47px;\n  width: 85%;\n  caret-color: black;\n}\n.login-container .el-input input {\n  height: 47px;\n  background: transparent;\n  border: 0px;\n  border-radius: 0px;\n  padding: 12px 5px 12px 15px;\n  color: #e0e0e0;\n  -webkit-appearance: none;\n}\n.login-container .el-input input:-webkit-autofill {\n  box-shadow: 0 0 0px 1000px #808080 inset !important;\n  -webkit-text-fill-color: #fff !important;\n}\n.login-container .el-form-item {\n  border: 1px solid rgba(255, 255, 255, 0.1);\n  background: rgba(0, 0, 0, 0.1);\n  border-radius: 5px;\n  color: #454545;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#333333",
	"menuText": "#fff",
	"menuActiveText": "#333333"
};
module.exports = exports;
