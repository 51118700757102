"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return !_vm.item.meta || !_vm.item.meta.hidden ? _c("div", {
    class: ["menu-wrapper", _vm.isCollapse ? "simple-mode" : "full-mode", {
      "first-level": _vm.isFirstLevel
    }]
  }, [!_vm.alwaysShowRootMenu && _vm.theOnlyOneChild && !_vm.theOnlyOneChild.children ? [_vm.theOnlyOneChild.meta ? _c("sidebar-item-link", {
    attrs: {
      to: _vm.resolvePath(_vm.theOnlyOneChild.path)
    }
  }, [_c("el-menu-item", {
    class: {
      "submenu-title-noDropdown": _vm.isFirstLevel
    },
    attrs: {
      index: _vm.resolvePath(_vm.theOnlyOneChild.path)
    }
  }, [_vm.theOnlyOneChild.meta.icon ? _c("unicon", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      name: _vm.theOnlyOneChild.meta.icon,
      width: "20",
      height: "20"
    }
  }) : _vm._e(), _vm.theOnlyOneChild.meta.title ? _c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$t("route." + _vm.theOnlyOneChild.meta.title)))]) : _vm._e()], 1)], 1) : _vm._e()] : _c("el-submenu", {
    attrs: {
      index: _vm.resolvePath(_vm.item.path),
      "popper-append-to-body": ""
    }
  }, [_c("template", {
    slot: "title"
  }, [_vm.item.meta && _vm.item.meta.icon ? _c("unicon", {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      name: _vm.item.meta.icon,
      width: "20",
      height: "20"
    }
  }) : _vm._e(), _vm.item.meta && _vm.item.meta.title ? _c("span", {
    attrs: {
      slot: "title"
    },
    slot: "title"
  }, [_vm._v(_vm._s(_vm.$t("route." + _vm.item.meta.title)))]) : _vm._e()], 1), _vm.item.children ? _vm._l(_vm.item.children, function (child) {
    return _c("sidebar-item", {
      key: child.path,
      staticClass: "nest-menu",
      attrs: {
        item: child,
        "is-collapse": _vm.isCollapse,
        "is-first-level": false,
        "base-path": _vm.resolvePath(child.path)
      }
    });
  }) : _vm._e()], 2)], 2) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;