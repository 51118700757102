"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateProduct = exports.updateOrder = exports.getProducts = exports.getProductInfo = exports.getProductCount = exports.getProductById = exports.getProductByEmail = exports.duplicateProduct = exports.deleteProduct = exports.defaultProductData = exports.availableValues = exports.availableOptions = exports.addProduct = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultProductData = exports.defaultProductData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0,
  description: '',
  video: '',
  modelNumber: '',
  productImages: [],
  categoriesId: null,
  subCategoryId: null,
  optionIds: null,
  unitId: null,
  qtymultiplier: 6,
  isDeleted: false,
  featureAndFacilities: [],
  inStock: true,
  new: true,
  discountedPrice: 0,
  offerProduct: false,
  mrp: 0,
  discount: 0
};
const getProducts = params => (0, _request.default)({
  url: '/products',
  method: 'get',
  params
});
exports.getProducts = getProducts;
const availableOptions = params => (0, _request.default)({
  url: '/products/available-options',
  method: 'get',
  params
});
exports.availableOptions = availableOptions;
const availableValues = params => (0, _request.default)({
  url: '/products/available-values',
  method: 'get',
  params
});
exports.availableValues = availableValues;
const getProductById = id => (0, _request.default)({
  url: `/products/${id}`,
  method: 'get'
});
exports.getProductById = getProductById;
const getProductInfo = data => (0, _request.default)({
  url: '/products/me',
  method: 'post',
  data
});
exports.getProductInfo = getProductInfo;
const getProductByEmail = Productemail => (0, _request.default)({
  url: `/products/${Productemail}`,
  method: 'get'
});
exports.getProductByEmail = getProductByEmail;
const updateProduct = (id, data) => (0, _request.default)({
  url: `/products/${id}`,
  method: 'patch',
  data
});
exports.updateProduct = updateProduct;
const deleteProduct = Productname => (0, _request.default)({
  url: `/products/${Productname}`,
  method: 'delete'
});
exports.deleteProduct = deleteProduct;
const addProduct = data => (0, _request.default)({
  url: '/products/',
  method: 'post',
  data
});
exports.addProduct = addProduct;
const duplicateProduct = data => (0, _request.default)({
  url: '/products/duplicate',
  method: 'post',
  data
});
exports.duplicateProduct = duplicateProduct;
const getProductCount = params => (0, _request.default)({
  url: '/products/count',
  method: 'get',
  params
});
exports.getProductCount = getProductCount;
const updateOrder = data => (0, _request.default)({
  url: '/products/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;