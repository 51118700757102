"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _product = require("@/api/product");
var _index = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _admin = require("@/store/modules/admin");
var _utils = require("@/utils");
var _excel = require("@/utils/excel");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
require("viewerjs/dist/viewer.css");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.myRole = _admin.AdminModule.roles;
    this.listLoading = true;
    this.newList = [];
    this.oldList = [];
    this.showSortingOption = false;
    this.renderComponent = true;
    this.downloadLoading = false;
    this.listQuery = parseInt(this.$route.params && this.$route.params.subCategoryId) ? {
      page: 1,
      limit: 20,
      sort: "priority,DESC",
      categoriesId: 0,
      subCategoryId: 0,
      isDeleted: false,
      filter: {
        enabled: "eq",
        name: "$contL",
        subCategoryId: "eq",
        categoriesId: "eq",
        modelNumber: "$contL",
        new: "eq",
        isDeleted: "eq",
        feature: "eq"
      }
    } : {
      page: 1,
      limit: 20,
      sort: "priority,DESC",
      categoriesId: 0,
      isDeleted: false,
      filter: {
        enabled: "eq",
        name: "$contL",
        categoriesId: "eq",
        modelNumber: "$contL",
        new: "eq",
        isDeleted: "eq",
        feature: "eq"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "Ascending",
      key: "priority,ASC"
    }, {
      label: "Descending",
      key: "priority,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.newOption = [{
      label: "Yes",
      key: true
    }, {
      label: "No",
      key: false
    }];
    this.visibilityOptions = [{
      key: true,
      value: 'On'
    }, {
      key: false,
      value: 'Off'
    }];
    this.ratingList = [{
      label: "1 ★",
      key: 1
    }, {
      label: "2 ★★",
      key: 2
    }, {
      label: "3 ★★★",
      key: 3
    }, {
      label: "4 ★★★★",
      key: 4
    }, {
      label: "5 ★★★★★",
      key: 5
    }];
    this.filters = [];
  }
  created() {
    this.listQuery.categoriesId = parseInt(this.$route.params && this.$route.params.categoriesId);
    if (parseInt(this.$route.params && this.$route.params.subCategoryId)) {
      this.listQuery.subCategoryId = parseInt(this.$route.params && this.$route.params.subCategoryId);
    }
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _product.getProducts)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async duplicate(productId) {
    const data = {
      id: productId
    };
    await (0, _product.duplicateProduct)(data);
    this.getList();
  }
  async handleIsDelete(row) {
    this.$confirm("This will permanently delete the file. Continue?", "Warning", {
      confirmButtonText: "OK",
      cancelButtonText: "Cancel",
      type: "warning"
    }).then(async () => {
      debugger;
      const data = row;
      data.isDeleted = true;
      await (0, _product.updateProduct)(row.id, data);
      this.$message({
        type: "success",
        message: "Delete completed"
      });
      this.getList();
    }).catch(() => {
      this.$message({
        type: "info",
        message: "Delete canceled"
      });
    });
    // const data: any = row
    // data.isDeleted = true;
    // await updateProduct(row.id, data);
    // this.getList()
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  async visibiltyUpdate(id, feature) {
    const data = await (0, _product.getProductById)(id);
    data.feature = feature;
    (0, _product.updateProduct)(id, data);
  }
  async newVisibiltyUpdate(id, newproduct) {
    const data = await (0, _product.getProductById)(id);
    data.new = newproduct;
    (0, _product.updateProduct)(id, data);
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      const data = await (0, _product.getProducts)((0, _utils.getQuery)({
        page: 1,
        limit: 100000,
        sort: "priority,ASC",
        enabled: true,
        isDeleted: false,
        filter: {
          enabled: "eq",
          isDeleted: "eq"
        }
      }));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: "sortable-ghost",
      setData: function (dataTransfer) {
        dataTransfer.setData("Text", "");
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
        this.updateOrder();
      }
    });
  }
  async updateOrder() {
    try {
      const updateCheck = await (0, _product.updateOrder)(this.newList);
      this.fetchAllRecored(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _product.deleteProduct)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Contact Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "email", "contactNumber", "enabled", "createdTimestamp"];
    const data = (0, _utils.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.notifications.title"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "TypeList",
  components: {
    Pagination: _index2.default,
    Enabled: _index.default
  }
})], default_1);
var _default = exports.default = default_1;