"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _option = require("@/api/option");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _index = _interopRequireDefault(require("@/components/Dropzone/index.vue"));
var _common = require("@/api/common");
var _index2 = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
var _index3 = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _moment = _interopRequireDefault(require("moment"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.productId = 0;
    this.showImageUpload = false;
    this.image = 'https://stumpz.s3.ap-south-1.amazonaws.com/1611434872392-img.png';
    this.file = '';
    this.params = {
      some_params: 'your_params_goes_here'
    };
    this.headers = {
      smail: '*_~'
    };
    this.validateRequire = (rule, value, callback) => {
      if (value === '') {
        if (rule.field === 'imageURL') {
          this.$message({
            message: 'Upload cover image is required',
            type: 'error'
          });
        } else {
          this.$message({
            message: rule.field + ' is required',
            type: 'error'
          });
        }
        callback(new Error(rule.field + ' is required'));
      } else {
        callback();
      }
    };
    this.postForm = Object.assign({}, _option.defaultOptionData);
    this.loading = false;
    this.rules = {
      name: [{
        required: true,
        message: 'Please enter name',
        trigger: ['blur', 'change']
      }, {
        min: 3,
        message: 'Length should be greater than 3',
        trigger: ['blur', 'change']
      }, {
        pattern: '^[a-zA-Z ]*$',
        message: 'Name should contain characters only',
        trigger: ['blur', 'change']
      }]
    };
  }
  onCropUploadSuccess(jsonData, field) {
    this.showImageUpload = false;
    this.image = jsonData.files[field];
  }
  data() {
    return {
      datePickerOptions: {
        disabledDate(date) {
          return (0, _moment.default)() >= date;
        }
      }
    };
  }
  cropSuccess(imgDataUrl, field) {
    this.image = imgDataUrl;
    const data = new FormData();
    fetch(imgDataUrl).then(res => res.blob()).then(blob => {
      const file = new File([blob], 'img.' + blob.type.split('/')[1], {
        type: 'image/png'
      });
      data.append('file', file);
      (0, _common.uploadFile)(data, event => {
        console.log(event);
      }).then(res => {
        this.postForm.image = res;
      });
    });
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    this.productId = parseInt(this.$route.params && this.$route.params.id);
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _option.defaultOptionData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _option.getOptionById)(id);
      this.postForm = data;
    } catch (err) {
      console.error(err);
    }
  }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        console.error('Submit Error!');
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  async saveForm() {
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _option.updateOption)(this.postForm.id, this.postForm);
      } else {
        await (0, _option.addOption)(this.postForm);
      }
      this.$notify({
        title: 'Success',
        message: 'Data saved successfully',
        type: 'success',
        duration: 2000
      });
      _router.default.push('/option/list/');
    } catch (err) {
      this.loading = false;
      console.error(err);
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'ProductOptionDetail',
  components: {
    Dropzone: _index.default,
    AvatarUpload: _index2.default,
    PanThumb: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;