// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.el-submenu.is-active > .el-submenu__title {\n  color: white !important;\n}\n.el-icon-arrow-down:before {\n  color: white;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title,\n.full-mode .el-submenu .el-menu-item {\n  min-width: 250px !important;\n  background-image: #1f2d3d !important;\n}\n.full-mode .nest-menu .el-submenu > .el-submenu__title.is-active,\n.full-mode .el-submenu .el-menu-item.is-active {\n  border-right: 5px solid white;\n  color: white !important;\n}\n.simple-mode.first-level .submenu-title-noDropdown {\n  padding: 0 !important;\n  position: relative;\n}\n.simple-mode.first-level .submenu-title-noDropdown .el-tooltip {\n  padding: 0 !important;\n}\n.simple-mode.first-level .el-menu-item {\n  text-align: center;\n}\n.simple-mode.first-level .unicon {\n  margin-right: 0 !important;\n}\n.simple-mode.first-level .el-submenu {\n  overflow: hidden;\n  text-align: center;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title {\n  padding: 0px !important;\n  background-color: #e3000f;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title .el-submenu__icon-arrow {\n  display: none;\n}\n.simple-mode.first-level .el-submenu > .el-submenu__title > span {\n  display: none;\n}\n.el-menu-item.is-active {\n  color: white !important;\n  background-color: #e3000f;\n}\n.unicon {\n  fill: white !important;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#333333",
	"menuText": "#fff",
	"menuActiveText": "#333333"
};
module.exports = exports;
