"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateContact = exports.getContactCount = exports.getContactById = exports.getContact = exports.deleteContact = exports.defaultContactData = exports.addContact = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultContactData = exports.defaultContactData = {
  id: 0,
  enabled: true,
  fullName: '',
  contactNumber: ''
};
const getContact = params => (0, _request.default)({
  url: '/contacts',
  method: 'get',
  params
});
exports.getContact = getContact;
const getContactById = id => (0, _request.default)({
  url: `/contacts/${id}`,
  method: 'get'
});
exports.getContactById = getContactById;
const updateContact = (id, data) => (0, _request.default)({
  url: `/contacts/${id}`,
  method: 'patch',
  data
});
exports.updateContact = updateContact;
const deleteContact = id => (0, _request.default)({
  url: `/contacts/${id}`,
  method: 'delete'
});
exports.deleteContact = deleteContact;
const addContact = data => (0, _request.default)({
  url: '/contacts/',
  method: 'post',
  data
});
exports.addContact = addContact;
const getContactCount = () => (0, _request.default)({
  url: '/contacts/count',
  method: 'get'
});
exports.getContactCount = getContactCount;