"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'education': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M88.9 119.6c-7.3 0-19.5 2.5-21.4 8.2v.1c-4.2.2-5.2 0-7 0-2-5.7-14.1-8.2-21.4-8.2H0V0h42.5C51.7 0 59.6 5.5 64 13.6 68.4 5.5 76.3 0 85.5 0H128v119.6H88.9zM60.4 24.8c0-9.7-9-16.5-17.7-16.5H7v103.1h32c7-.1 18.2.1 21.3 6.2V24.8zM121 8.2H85.3c-8.8 0-17.7 6.9-17.7 16.5v92.7c3.1-6 14.2-6.2 21.3-6h32V8.1z"/>'
  }
});