import { render, staticRenderFns } from "./AdminDetail.vue?vue&type=template&id=47f4bb41&scoped=true"
import script from "./AdminDetail.vue?vue&type=script&lang=ts"
export * from "./AdminDetail.vue?vue&type=script&lang=ts"
import style0 from "./AdminDetail.vue?vue&type=style&index=0&id=47f4bb41&lang=scss"
import style1 from "./AdminDetail.vue?vue&type=style&index=1&id=47f4bb41&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47f4bb41",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\algoocean\\tessco-website-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47f4bb41')) {
      api.createRecord('47f4bb41', component.options)
    } else {
      api.reload('47f4bb41', component.options)
    }
    module.hot.accept("./AdminDetail.vue?vue&type=template&id=47f4bb41&scoped=true", function () {
      api.rerender('47f4bb41', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/components/AdminDetail.vue"
export default component.exports