"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
var _admin = _interopRequireDefault(require("./modules/admin"));
var _banner = _interopRequireDefault(require("./modules/banner"));
var _category = _interopRequireDefault(require("./modules/category"));
var _product = _interopRequireDefault(require("./modules/product"));
var _subCategory = _interopRequireDefault(require("./modules/sub-category"));
var _gallery = _interopRequireDefault(require("./modules/gallery"));
var _contact = _interopRequireDefault(require("./modules/contact"));
var _user = _interopRequireDefault(require("./modules/user"));
var _order = _interopRequireDefault(require("./modules/order"));
var _options = _interopRequireDefault(require("./modules/options"));
var _optionValues = _interopRequireDefault(require("./modules/optionValues"));
/* Layout */

/* Router modules */

_vue.default.use(_vueRouter.default);
/*
  Note: sub-menu only appear when children.length>=1
  Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
*/
/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['superadmin','admin','executive']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/
/**
  ConstantRoutes
  a base page that does not have permission requirements
  all roles can be accessed
*/
const constantRoutes = exports.constantRoutes = [{
  path: '/redirect',
  component: _index.default,
  meta: {
    hidden: true
  },
  children: [{
    path: '/redirect/:path*',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "redirect" */'@/views/redirect/index.vue')))
  }]
}, {
  path: '/login',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */'@/views/login/index.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/auth-redirect',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "auth-redirect" */'@/views/login/auth-redirect.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/404',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "404" */'@/views/error-page/404.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/401',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "401" */'@/views/error-page/401.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "dashboard" */'@/views/dashboard/index.vue'))),
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/profile',
  component: _index.default,
  redirect: '/profile/index',
  meta: {
    hidden: true
  },
  children: [{
    path: 'index',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "profile" */'@/views/profile/index.vue'))),
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}];
/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
*/
const asyncRoutes = exports.asyncRoutes = [/** when your routing map is too long, you can split it into small modules **/
// {
//   path: '/masters',
//   redirect: 'noredirect',
//   component: () => import(/* webpackChunkName: "dashboard" */ '@/views/admin/empty.vue'),
//   name: 'master',
//   meta: {
//     title: 'masterManagement.title',
//     icon: 'sitemap'
//   },
//   children: [
//   ]
// },
_admin.default, _user.default, _order.default, _category.default, _product.default, _banner.default, _options.default, _optionValues.default,
// NotificationRouter,
// SettingRouter,
_subCategory.default, _gallery.default,
// testimonialRouter,
_contact.default, {
  path: '/forgotPassword',
  component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require( /* webpackChunkName: "login" */'@/views/login/forgotPassword.vue'))),
  meta: {
    hidden: true
  }
}, {
  path: '*',
  redirect: '/404',
  meta: {
    hidden: true
  }
}];
const createRouter = () => new _vueRouter.default({
  // mode: 'history',  // Disabled due to Github Pages doesn't support this, enable this if you need.
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0
      };
    }
  },
  base: process.env.BASE_URL,
  routes: constantRoutes
});
const router = createRouter();
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = exports.default = router;