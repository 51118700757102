"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _vueCountTo = _interopRequireDefault(require("vue-count-to"));
var _BarChart = _interopRequireDefault(require("./BarChart.vue"));
var _LineChart = _interopRequireDefault(require("../components/LineChart.vue"));
var _PieChart = _interopRequireDefault(require("./PieChart.vue"));
var _RadarChart = _interopRequireDefault(require("./RadarChart.vue"));
var _utils = require("@/utils");
var _banner = require("@/api/banner");
var _category = require("@/api/category");
var _product = require("@/api/product");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
const lineChartData = {
  userCounts: {
    actualData: [120, 82, 91, 154, 162, 140, 145],
    expectedData: [200, 192, 120, 144, 160, 130, 140]
  }
};
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.listLoading = true;
    this.tableKey = 0;
    this.total = 0;
    this.UsersCount = 0;
    this.bannerTotal = 0;
    this.Users = 0;
    this.categoriesCount = 0;
    this.productCount = 0;
    this.lineChartData = lineChartData.userCounts;
  }
  handleSetLineChartData(type) {
    this.$emit('handleSetLineChartData', type);
  }
  created() {
    this.getBannerCount();
    this.getCategorysCount();
    this.getProductCount();
  }
  async getBannerCount() {
    try {
      this.listLoading = true;
      const data = await (0, _banner.getBannerCount)((0, _utils.getQuery)({
        filter: {}
      }));
      this.bannerTotal = data;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getCategorysCount() {
    try {
      this.listLoading = true;
      this.categoriesCount = await (0, _category.getCategorysCount)((0, _utils.getQuery)({
        filter: {}
      }));
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  async getProductCount() {
    try {
      this.listLoading = true;
      this.productCount = await (0, _product.getProductCount)((0, _utils.getQuery)({
        filter: {}
      }));
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'PanelGroup',
  components: {
    CountTo: _vueCountTo.default,
    LineChart: _LineChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default,
    RadarChart: _RadarChart.default,
    Pagination: _index.default
  }
})], default_1);
var _default = exports.default = default_1;