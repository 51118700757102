"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateSubCategory = exports.updateOrder = exports.getSubSubCategorysCount = exports.getSubCategorys = exports.getSubCategoryInfo = exports.getSubCategoryById = exports.getSubCategoryByEmail = exports.deleteSubCategory = exports.defaultSubCategoryData = exports.addSubCategory = exports.StoreStoreSubCategory = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultSubCategoryData = exports.defaultSubCategoryData = {
  id: 0,
  enabled: true,
  name: '',
  image: '',
  priority: 0,
  createdByFullName: '',
  isDeleted: false,
  categoriesId: null
};
const getSubCategorys = params => (0, _request.default)({
  url: '/sub-category',
  method: 'get',
  params
});
exports.getSubCategorys = getSubCategorys;
const getSubCategoryById = id => (0, _request.default)({
  url: `/sub-category/${id}`,
  method: 'get'
});
exports.getSubCategoryById = getSubCategoryById;
const getSubCategoryInfo = data => (0, _request.default)({
  url: '/sub-category/me',
  method: 'post',
  data
});
exports.getSubCategoryInfo = getSubCategoryInfo;
const getSubCategoryByEmail = StoreSubCategoryemail => (0, _request.default)({
  url: `/sub-category/${StoreSubCategoryemail}`,
  method: 'get'
});
exports.getSubCategoryByEmail = getSubCategoryByEmail;
const updateSubCategory = (id, data) => (0, _request.default)({
  url: `/sub-category/${id}`,
  method: 'patch',
  data
});
exports.updateSubCategory = updateSubCategory;
const StoreStoreSubCategory = (id, data) => (0, _request.default)({
  url: `/sub-category/${id}`,
  method: 'patch',
  data
});
exports.StoreStoreSubCategory = StoreStoreSubCategory;
const deleteSubCategory = StoreSubCategoryname => (0, _request.default)({
  url: `/sub-category/${StoreSubCategoryname}`,
  method: 'delete'
});
exports.deleteSubCategory = deleteSubCategory;
const addSubCategory = data => (0, _request.default)({
  url: '/sub-category',
  method: 'post',
  data
});
exports.addSubCategory = addSubCategory;
const getSubSubCategorysCount = params => (0, _request.default)({
  url: '/sub-category/count',
  method: 'get',
  params
});
exports.getSubSubCategorysCount = getSubSubCategorysCount;
const updateOrder = data => (0, _request.default)({
  url: '/sub-category/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;