"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
var _vuexModuleDecorators = require("vuex-module-decorators");
var _productOptionValues = require("@/api/productOptionValues");
var _product = require("@/api/product");
var _router = _interopRequireDefault(require("./../../../router"));
var _common = require("@/api/common");
var _axios = _interopRequireDefault(require("axios"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.optionId = 0;
    this.productId = 0;
    this.optionValuesList = [];
    this.postForm = Object.assign({}, _productOptionValues.defaultProductOptionValuesData);
    this.loading = false;
    this.list = [];
    this.myRole = _admin.AdminModule.roles;
    this.listLoading = true;
    this.newList = [];
    this.oldList = [];
    this.showSortingOption = false;
    this.renderComponent = true;
    this.downloadLoading = false;
    this.fileList = [];
    this.fileListFeature = [];
    this.editList = [];
    this.dialogImageUrl = "";
    this.dialogImageUrlFeature = "";
    this.imageEdited = false;
    this.dialogVisible = false;
    this.dialogVisibleFeature = false;
    this.disabled = false;
    this.filters = [];
    this.rules = {
      optionValueId: [{
        required: true,
        message: 'Please Choose a value',
        trigger: ['blur', 'change']
      }]
    };
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.optionId = parseInt(this.$route.params && this.$route.params.optionId);
      this.productId = parseInt(this.$route.params && this.$route.params.productId);
      this.postForm.productOptionId = parseInt(this.$route.params && this.$route.params.id);
      this.fetchOptionValues();
    }
  }
  async fetchData(id) {
    try {
      var _data$images;
      const data = await (0, _productOptionValues.getProductOptionValueById)(id);
      this.postForm = data;
      console.log('postform data: ', data);
      this.optionId = data.productOption.optionId;
      (_data$images = data.images) === null || _data$images === void 0 || _data$images.forEach((image, index) => {
        this.fileList.push({
          name: "image1" + index,
          url: image
        });
      });
      debugger;
      this.optionValue = data.optionValue;
      this.fetchOptionValues();
    } catch (err) {
      Promise.reject(err);
    }
  }
  resetForm() {
    this.$refs.postForm.resetFields();
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      ;
      this.loading = true;
      if (this.isEdit) {
        debugger;
        if (this.imageEdited) {
          await this.finalUpload();
        }
        await (0, _productOptionValues.updateProductOptionValue)(this.postForm.id, this.postForm).then(response => {
          ;
          this.$notify({
            title: "Success",
            message: "Data updated successfully",
            type: "success",
            duration: 2000
          });
          debugger;
          _router.default.push("/product/values/" + this.postForm.productOptionId + "/" + this.optionId + "/" + this.productId);
        }).catch(error => {
          this.loading = false;
          this.$message.error("some error occured.");
          console.log(error);
        });
      } else {
        await this.finalUpload();
        await (0, _productOptionValues.addProductOptionValue)(this.postForm).then(response => {
          this.$notify({
            title: "Success",
            message: "Data saved successfully",
            type: "success",
            duration: 2000
          });
          _router.default.push("/product/values/" + this.postForm.productOptionId + "/" + this.optionId + "/" + this.productId);
        }).catch(error => {
          this.loading = false;
          this.$message.error("some error occured.");
          console.log(error);
        });
      }
    } catch (err) {
      this.loading = false;
    }
  }
  async fetchOptionValues() {
    const data = {
      productOptionId: this.postForm.productOptionId,
      optionsId: this.optionId
    };
    try {
      const response = await (0, _product.availableValues)(data);
      this.optionValuesList = response.values;
    } catch (err) {
      Promise.reject(err);
    }
  }
  async uploadImages(file, fileList) {
    const img = new Image();
    img.onload = () => {
      this.fileList = fileList;
      if (this.isEdit) {
        this.imageEdited = true;
        this.editList.push(file);
      }
    };
    img.src = file.url;
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  handleRemove(file) {
    this.fileList.forEach((photo, index) => {
      if (photo.name === file.name) {
        var _this$postForm$images;
        this.fileList.splice(index, 1);
        (_this$postForm$images = this.postForm.images) === null || _this$postForm$images === void 0 || _this$postForm$images.splice(index, 1);
      }
    });
  }
  // Image upload size check
  beforeUpload(file) {
    const isJPG = file.type === "image/jpeg";
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isJPG) {
      this.$message.error("Avatar picture must be JPG format!");
    }
    if (!isLt2M) {
      this.$message.error("Avatar picture size can not exceed 2MB!");
    }
    return isJPG && isLt2M;
  }
  async finalUpload() {
    if (!this.isEdit) {
      const formList = [];
      const callList = [];
      this.fileList.forEach(file => {
        var fileObj = file.raw;
        var form = new FormData();
        form.append("file", fileObj);
        formList.push(form);
      });
      await formList.forEach(async form => {
        callList.push((0, _common.uploadFile)(form, async event => {
          console.log(event);
        }));
      });
      await _axios.default.all(callList).then(res => {
        const images = res.map(item => String(item));
        this.postForm.images = images;
      });
    } else if (this.isEdit) {
      const formList = [];
      const callList = [];
      this.editList.forEach(file => {
        var fileObj = file.raw;
        var form = new FormData();
        form.append("file", fileObj);
        formList.push(form);
      });
      await formList.forEach(async form => {
        callList.push((0, _common.uploadFile)(form, async event => {
          console.log(event);
        }));
      });
      await _axios.default.all(callList).then(async res => {
        console.log('this.postForm res: ', res);
        const images = res.map(item => String(item));
        this.postForm.images = images;
      });
      console.log('this.postForm.images: ', this.postForm.images);
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "TypeList",
  components: {
    Pagination: _index.default,
    Enabled: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;