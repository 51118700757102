"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateBanner = exports.getBanners = exports.getBannerInfo = exports.getBannerCount = exports.getBannerById = exports.getBannerByEmail = exports.deleteBanner = exports.defaultBannerData = exports.addBanner = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultBannerData = exports.defaultBannerData = {
  id: 0,
  enabled: true,
  isMobile: false,
  // clickCount: 0,
  createdBy: 0,
  image: '',
  text: ''
  // redirectionOption: null,
  // redirectionValue: null,
  // priority: 0
};
const getBanners = params => (0, _request.default)({
  url: '/banners',
  method: 'get',
  params
});
exports.getBanners = getBanners;
const getBannerById = id => (0, _request.default)({
  url: `/banners/${id}`,
  method: 'get'
});
exports.getBannerById = getBannerById;
const getBannerInfo = data => (0, _request.default)({
  url: '/banners/me',
  method: 'post',
  data
});
exports.getBannerInfo = getBannerInfo;
const getBannerByEmail = Banneremail => (0, _request.default)({
  url: `/banners/${Banneremail}`,
  method: 'get'
});
exports.getBannerByEmail = getBannerByEmail;
const updateBanner = (id, data) => (0, _request.default)({
  url: `/banners/${id}`,
  method: 'patch',
  data
});
exports.updateBanner = updateBanner;
const deleteBanner = Bannername => (0, _request.default)({
  url: `/banners/${Bannername}`,
  method: 'delete'
});
exports.deleteBanner = deleteBanner;
const addBanner = data => (0, _request.default)({
  url: '/banners/',
  method: 'post',
  data
});
exports.addBanner = addBanner;
const getBannerCount = params => (0, _request.default)({
  url: '/banners/count',
  method: 'get',
  params
});
exports.getBannerCount = getBannerCount;
const updateOrder = data => (0, _request.default)({
  url: '/banners/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;