"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _order = require("@/api/order");
var _cart = require("@/api/cart");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _index2 = require("@/utils/index");
var _excel = require("@/utils/excel");
var _index3 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _axios = _interopRequireDefault(require("axios"));
var _reason = require("@/api/reason");
var _moment = _interopRequireDefault(require("moment"));
// import { htmlToPaper } from "vue-html-to-paper";

// import { getVendor } from "@/api/vendor";
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.reason = "";
    this.status = 0;
    this.orderList = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.dynamicTags = ["Tag 1", "Tag 2", "Tag 3"];
    this.orderData = Object.assign({}, _order.defaultOrderData);
    this.dialogVisible = false;
    this.reasonDialogVisible = false;
    this.dialogLoading = false;
    this.statusDialogVisible = false;
    this.reasonDialogLoading = false;
    this.activeTab = "newOrders";
    this.statusDialogLoading = false;
    this.fullscreenLoading = false;
    this.newList = [];
    this.oldList = [];
    this.showSortingOption = false;
    this.renderComponent = true;
    this.currentPage = 0; // pdf file page number
    this.pageCount = 0; // total number of pages in pdf file
    this.fileType = "pdf"; // file type
    // vendorID:any=null;
    this.reasonList = [];
    this.orderItem = [];
    this.cardDetails = [];
    this.listQuery = {
      sort: "id,DESC",
      page: 1,
      limit: 10,
      status: this.status,
      startDate: "",
      endDate: "",
      createdTimestamp: "",
      createdTimestamp_1: "",
      isDeleted: false,
      filter: {
        createdTimestamp: "gte",
        createdTimestamp_1: "lte"
      }
    };
    this.listQuery1 = {
      sort: "id,DESC",
      page: 1,
      limit: 10,
      status: this.status,
      startDate: "",
      endDate: "",
      createdTimestamp: "",
      createdTimestamp_1: "",
      isDeleted: false,
      filter: {
        createdTimestamp: "gte",
        createdTimestamp_1: "lte"
      }
    };
    // private print() {
    //   // Pass the element id here
    //   htmlToPaper("orderBill");
    // }
    this.sortOptions = [{
      label: "ID Ascending",
      key: "id,ASC"
    }, {
      label: "ID Descending",
      key: "id,DESC"
    }];
    this.statusList = [{
      label: "New Orders",
      value: 0
    }, {
      label: "Under Reviewed",
      value: 1
    }, {
      label: "Ready to Dispatch",
      value: 2
    }, {
      label: "Dispatched",
      value: 3
    }, {
      label: "Delivered",
      value: 4
    }, {
      label: "Rejected",
      value: 5
    }, {
      label: "Paid",
      value: 6
    }, {
      label: "Verified",
      value: 7
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  OpenStatus(row) {
    this.orderData = row;
    this.statusDialogVisible = true;
  }
  RejectOrderStatus(row) {
    this.orderData = row;
    debugger;
    this.reasonDialogVisible = true;
  }
  created() {
    this.setDefaultOrderDate();
    //this.fetchVendors();
    this.fetchReasons();
    this.getList();
    if (this.$route.query.tabName != null) {
      this.activeTab = this.$route.query.tabName;
    }
  }
  setDefaultOrderDate() {
    var date = (0, _moment.default)();
    this.listQuery.createdTimestamp = (0, _moment.default)(date).startOf("day").hour(0).toISOString();
    this.listQuery.createdTimestamp_1 = (0, _moment.default)(date).endOf("day").hour(23).toISOString();
  }
  async getList() {
    try {
      this.listLoading = true;
      this.list = [];
      const data = await (0, _order.getAllOrders)((0, _index2.getQuery)(this.listQuery));
      this.list = data;
      this.total = data.length;
      var callList = [];
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  //  private handleClick(tab: any, event: any) {
  //   console.log(tab, event);
  //   this.status = Number(tab.index);
  //   this.listQuery.status = Number(tab.index);
  //   this.getList();
  // }
  handleClick(tab, event) {
    this.status = Number(tab.index);
    this.listQuery.status = Number(tab.index);
    if (Number(tab.index) === 5) {
      this.listQuery.status = 6;
    }
    if (Number(tab.index) === 6) {
      this.listQuery.status = 5;
    }
    this.getList();
  }
  async fetchReasons() {
    try {
      const data = await (0, _reason.getReasons)((0, _index2.getQuery)({
        filter: {}
      }));
      this.reasonList = data;
    } catch (error) {
      console.log(error);
    }
  }
  async getOrderData(id) {
    try {
      this.orderItem = [];
      const data = await (0, _order.getOrderById)(id);
      data.product.addToCart.forEach(item => {
        let abc = {
          name: item.product.name,
          qty: item.quantity
        };
        this.orderItem.push(abc);
      });
    } catch (err) {
      console.log(err);
    }
  }
  async getCardData(userCard) {
    try {
      this.cardDetails = [];
      this.cardDetails.push(userCard);
    } catch (err) {
      console.log(err);
    }
  }
  // To Recieve new order and to update status
  async updateOrderStatus(row, status) {
    row.status = status;
    let StatusData = {
      id: row.id,
      status: row.status,
      reasonId: row.reasonId
    };
    this.fullscreenLoading = true;
    (0, _order.updateStatus)(StatusData).then(response => {
      this.reasonDialogVisible = false;
      this.reasonDialogLoading = false;
      this.fullscreenLoading = true;
      this.getList();
      this.$message({
        message: "Success.",
        type: "success"
      });
      this.fullscreenLoading = false;
    }).catch(error => {
      this.$message({
        type: "info",
        message: error.message
      });
    });
  }
  async getOrders(row) {
    this.orderList = [];
    var callList = [];
    this.fullscreenLoading = true;
    await row.addToCartIds.forEach(async cartId => {
      callList.push((0, _cart.getCartById)(cartId));
    });
    await _axios.default.all(callList).then(data => {
      data.forEach((response, index) => {
        this.orderList.push(response);
      });
    });
  }
  toggleVisibility() {
    this.dialogVisible = true;
  }
  async updateStatus() {
    const data = this.orderData;
    if (data.status != 5) {
      data.reasonId = null;
    }
    if (data.status == 6) {
      this.fullscreenLoading = true;
      await (0, _order.updateStatus)({
        id: data.id,
        status: data.status,
        transactionid: data.transactionid
      }).then(res => {
        this.$message({
          message: "Order Status Updated.",
          type: "success"
        });
      }).catch(error => {
        console.log(error);
        this.fullscreenLoading = false;
      });
    } else if (data.status == 5) {
      this.fullscreenLoading = true;
      await (0, _order.updateStatus)({
        id: data.id,
        status: data.status,
        reasonId: data.reasonId
      }).then(() => {
        this.$message({
          message: "Order Status Updated.",
          type: "success"
        });
      }).catch(error => {
        console.log(error);
        this.fullscreenLoading = false;
      });
    } else {
      this.fullscreenLoading = true;
      await (0, _order.updateStatus)({
        id: data.id,
        status: data.status
      }).then(() => {
        this.$message({
          message: "Order Status Updated.",
          type: "success"
        });
      }).catch(error => {
        console.log(error);
        this.fullscreenLoading = false;
      });
    }
    this.getList();
    this.statusDialogVisible = false;
    this.fullscreenLoading = false;
  }
  fromUtc(field) {
    if (field == null) {
      this.listQuery.startDate = "";
      this.handleFilter();
    } else {
      this.listQuery.startDate = field.toISOString();
      this.handleFilter();
    }
  }
  toUtc(field) {
    if (field == null) {
      this.listQuery.endDate = "";
      this.handleFilter();
    } else {
      this.listQuery.endDate = (0, _moment.default)(field).endOf("day").hour(23).toISOString();
      this.handleFilter();
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  async fetchProducts(ids) {
    const products = [];
    const callList = [];
    await ids.forEach(async id => {
      callList.push((0, _cart.getCartById)(id));
    });
    _axios.default.all(callList).then(data => {
      data.forEach(response => {
        products.push(response);
      });
    });
    return products;
  }
  async handleDownload(row) {
    this.downloadLoading = true;
    const orderData = row;
    const products = [];
    const callList = [];
    await orderData.addToCartIds.forEach(async id => {
      await callList.push((0, _cart.getCartById)(id));
    });
    await _axios.default.all(callList).then(data => {
      data.forEach(async response => {
        await products.push(response);
      });
    });
    const user = orderData.user;
    products.forEach(product => {
      var options = "";
      for (var key in product.options) {
        options = options.concat((key + ":" + product.options[key]).toString() + " ,");
      }
      if (options.length < 1) {
        options = "NA";
      }
      product.optionsName = options;
      product.user = user;
    });
    const tHeader = ["Model Numer", "Options", "Quantity"];
    const merges = ["A1:E1"];
    const multiHeader = [[user.companyName, "", ""]];
    const filterVal = ["product.modelNumber", "optionsName", "quantity"];
    const data = (0, _index2.formatJson)(filterVal, products);
    const heading = [];
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc(user.companyName + "_" + (0, _moment.default)(orderData.createdTimestamp).format("DD-MM-YYYY")), multiHeader, merges);
    // exportJson2Excel(
    //   tHeader,
    //   data, this.$tc(user.companyName + '_' + moment(orderData.createdTimestamp).format('DD-MM-YYYY'),multiHeader, merges
    //   )
    // )
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "orderList",
  components: {
    Pagination: _index.default,
    Enabled: _index3.default
  }
})], default_1);
var _default = exports.default = default_1;