"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateProductOptionIsSelected = exports.updateProductOption = exports.updateOrder = exports.getProductOptions = exports.getProductOptionInfo = exports.getProductOptionCount = exports.getProductOptionById = exports.getProductOptionByEmail = exports.deleteProductOption = exports.defaultProductOptionData = exports.addProductOption = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultProductOptionData = exports.defaultProductOptionData = {
  id: 0,
  enabled: true,
  productId: null,
  optionId: null
};
const getProductOptions = params => (0, _request.default)({
  url: '/product-options',
  method: 'get',
  params
});
exports.getProductOptions = getProductOptions;
const getProductOptionById = id => (0, _request.default)({
  url: `/product-options/${id}`,
  method: 'get'
});
exports.getProductOptionById = getProductOptionById;
const getProductOptionInfo = data => (0, _request.default)({
  url: '/product-options/me',
  method: 'post',
  data
});
exports.getProductOptionInfo = getProductOptionInfo;
const getProductOptionByEmail = ProductOptionsemail => (0, _request.default)({
  url: `/product-options/${ProductOptionsemail}`,
  method: 'get'
});
exports.getProductOptionByEmail = getProductOptionByEmail;
const updateProductOption = (id, data) => (0, _request.default)({
  url: `/product-options/${id}`,
  method: 'patch',
  data
});
exports.updateProductOption = updateProductOption;
const updateProductOptionIsSelected = data => (0, _request.default)({
  url: `/product-options/select`,
  method: 'patch',
  data
});
exports.updateProductOptionIsSelected = updateProductOptionIsSelected;
const deleteProductOption = ProductOptionsname => (0, _request.default)({
  url: `/product-options/${ProductOptionsname}`,
  method: 'delete'
});
exports.deleteProductOption = deleteProductOption;
const addProductOption = data => (0, _request.default)({
  url: '/product-options/',
  method: 'post',
  data
});
exports.addProductOption = addProductOption;
const getProductOptionCount = params => (0, _request.default)({
  url: '/product-options/count',
  method: 'get',
  params
});
exports.getProductOptionCount = getProductOptionCount;
const updateOrder = data => (0, _request.default)({
  url: '/product-options/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;