"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _order = require("@/api/order");
var _vuePropertyDecorator = require("vue-property-decorator");
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.list = [];
    this.products = [];
    this.totalQty = 0;
    this.totalAmt = 0;
    this.taxAmt = 0;
    this.disAmt = 0;
    this.grandTotal = 0;
    this.wtdiscountTotal = 0;
  }
  printDiv(divName) {
    var _document$getElementB;
    var printContents = (_document$getElementB = document.getElementById(divName)) === null || _document$getElementB === void 0 ? void 0 : _document$getElementB.innerHTML;
    //  var originalContents = document.body.innerHTML;
    //  document.body.innerHTML = printContents;
    //  window.print();
    //  document.body.innerHTML = originalContents;
    var printWindow = window.open("", "", "height=800,width=1000");
    printWindow.document.write(printContents);
    printWindow.document.close();
    setTimeout(function () {
      printWindow.print();
    }, 1000);
  }
  created() {
    const orderId = parseInt(this.$route.params && this.$route.params.id);
    this.getData(orderId);
  }
  async getData(id) {
    const data = await (0, _order.getOrderById)(id);
    this.products = data.product.addToCart;
    this.list = data.product;
    this.totalAmt = data.product.totalAmount;
    // this.taxAmt = data.product.taxAmount;
    this.disAmt = data.product.discountAmount == null ? 0 : data.product.discountAmount;
    // this.grandTotal =  this.wtdiscountTotal - this.disAmt;
    //counting total qunatity of products
    for (var i = 0; i < this.products.length; i++) {
      this.totalQty += this.products[i].quantity;
      this.wtdiscountTotal += this.products[i].quantity * this.products[i].product.actualPrice;
    }
    this.grandTotal = this.wtdiscountTotal - this.disAmt;
    // await this.fetchUnits()
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "orderPdf",
  components: {}
})], default_1);
var _default = exports.default = default_1;