"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _excel = require("@/utils/excel");
var _utils = require("@/utils");
var _index2 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _banner = require("@/api/banner");
var _admin = require("@/store/modules/admin");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.list = [];
    this.mallsList = [];
    this.listLoading = true;
    this.downloadLoading = false;
    this.myRole = _admin.AdminModule.roles;
    this.showSortingOption = false;
    this.renderComponent = true;
    this.newList = [];
    this.oldList = [];
    this.listQuery = {
      page: 1,
      limit: 10,
      sort: "order,ASC",
      filter: {
        enabled: "eq"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "Ascending",
      key: "order,ASC"
    }, {
      label: "Descending",
      key: "order,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
  }
  created() {
    this.getList();
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      const data = await (0, _banner.getBanners)({
        page: 1,
        limit: 100000,
        sort: "order,ASC"
      });
      this.list = data.data;
      this.total = data.length;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: "sortable-ghost",
      setData: function (dataTransfer) {
        dataTransfer.setData("Text", "");
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
        this.updateOrder();
      }
    });
  }
  async updateOrder() {
    try {
      const updateCheck = await (0, _banner.updateOrder)(this.newList);
      this.fetchAllRecored(false);
    } catch (error) {}
  }
  handleDelete(data) {
    if (data) {
      this.$confirm("Do you want to delete this banner?", this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _banner.deleteBanner)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _banner.getBanners)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
    } catch (error) {
      this.listLoading = false;
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Contact Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "email", "contactNumber", "enabled", "createdTimestamp"];
    const data = (0, _utils.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.BannerManagement.Banners.title"));
    this.downloadLoading = false;
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "BannerList",
  components: {
    Pagination: _index.default,
    Enabled: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;