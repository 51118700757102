"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
var _vueSvgicon = _interopRequireDefault(require("vue-svgicon"));
/* eslint-disable */
/* tslint:disable */
// @ts-ignore

_vueSvgicon.default.register({
  'lock': {
    width: 128,
    height: 128,
    viewBox: '0 0 128 128',
    data: '<path pid="0" d="M119.9 49.7h-8V39.5C111.9 17.7 90.4.1 64 .1 37.5 0 16.1 17.7 16.1 39.5v10.2h-8c-4.4 0-8 3-8 6.6v65c0 3.7 3.6 6.7 8 6.7H120c4.4 0 8-3 8-6.6V56.3c0-3.7-3.6-6.6-8-6.6zm-24 0H32.1V39.5C32 25 46.4 13.2 64 13.2c17.6 0 32 11.8 32 26.3v10.2z"/>'
  }
});