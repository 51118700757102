"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "form-block"
  }, [_c("div", {
    staticClass: "source"
  }, [_c("el-form", {
    ref: "postForm",
    staticClass: "demo-form",
    attrs: {
      model: _vm.postForm,
      rules: _vm.rules,
      "label-width": "160px",
      "label-position": "left"
    }
  }, [_c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Enabled",
      prop: "enabled"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "enabled", $$v);
      },
      expression: "postForm.enabled"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Name",
      prop: "name"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "name",
      required: "",
      placeholder: "Name"
    },
    model: {
      value: _vm.postForm.name,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "name", $$v);
      },
      expression: "postForm.name"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Description",
      prop: "description"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "description",
      type: "textarea",
      rows: 4,
      required: "",
      placeholder: "Description"
    },
    model: {
      value: _vm.postForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "description", $$v);
      },
      expression: "postForm.description"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Featured Image",
      prop: "image"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      limit: 1,
      action: "https://website.tesscomobiles.com/common/upload",
      "on-change": _vm.handleChange,
      "on-exceed": _vm.handleExceed,
      "file-list": _vm.fileListFeature,
      drag: "",
      "list-type": "picture-card"
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function ({
        file
      }) {
        return _c("div", {}, [_c("img", {
          staticClass: "el-upload-list__item-thumbnail",
          attrs: {
            src: file.url,
            alt: ""
          }
        }), _c("span", {
          staticClass: "el-upload-list__item-actions"
        }, [_c("span", {
          staticClass: "el-upload-list__item-preview",
          on: {
            click: function ($event) {
              return _vm.handlePictureCardPreviewFeatured(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-zoom-in"
        })]), !_vm.disabledFeature ? _c("span", {
          staticClass: "el-upload-list__item-delete",
          on: {
            click: function ($event) {
              return _vm.handleRemoveFeatured(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-delete"
        })]) : _vm._e()])]);
      }
    }])
  }, [_c("i", {
    staticClass: "el-icon-plus",
    attrs: {
      slot: "default"
    },
    slot: "default"
  })]), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisibleFeature
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisibleFeature = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrlFeature,
      alt: ""
    }
  })]), _c("span", {
    staticStyle: {
      color: "red"
    }
  }, [_vm._v("* Size 360 x 250")])], 1), _c("el-form-item", {
    attrs: {
      label: "Product images",
      prop: "productImages"
    }
  }, [_c("el-upload", {
    ref: "upload",
    staticClass: "avatar-uploader",
    attrs: {
      "on-change": _vm.uploadImages,
      "list-type": "picture-card",
      action: "https://jsonplaceholder.typicode.com/posts/",
      accept: "image/jpeg,image/gif,image/png",
      "before-upload": _vm.beforeUpload,
      "auto-upload": false,
      "file-list": _vm.fileList,
      drag: "",
      multiple: ""
    },
    scopedSlots: _vm._u([{
      key: "file",
      fn: function ({
        file
      }) {
        return _c("div", {}, [_c("img", {
          staticClass: "el-upload-list__item-thumbnail",
          attrs: {
            src: file.url,
            alt: ""
          }
        }), _c("span", {
          staticClass: "el-upload-list__item-actions"
        }, [_c("span", {
          staticClass: "el-upload-list__item-preview",
          on: {
            click: function ($event) {
              return _vm.handlePictureCardPreview(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-zoom-in"
        })]), !_vm.disabled ? _c("span", {
          staticClass: "el-upload-list__item-delete",
          on: {
            click: function ($event) {
              return _vm.handleRemove(file);
            }
          }
        }, [_c("i", {
          staticClass: "el-icon-delete"
        })]) : _vm._e()])]);
      }
    }])
  }, [_c("i", {
    staticClass: "el-icon-plus",
    attrs: {
      slot: "default"
    },
    slot: "default"
  })]), _c("el-dialog", {
    attrs: {
      visible: _vm.dialogVisible
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      }
    }
  }, [_c("img", {
    attrs: {
      width: "100%",
      src: _vm.dialogImageUrl,
      alt: ""
    }
  })])], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px",
      "margin-top": "40px"
    },
    attrs: {
      label: "In Stock",
      prop: "inStock"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.inStock,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "inStock", $$v);
      },
      expression: "postForm.inStock"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px",
      "margin-top": "40px"
    },
    attrs: {
      label: "New",
      prop: "new"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.new,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "new", $$v);
      },
      expression: "postForm.new"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Feature",
      prop: "feature"
    }
  }, [_c("el-switch", {
    model: {
      value: _vm.postForm.feature,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "feature", $$v);
      },
      expression: "postForm.feature"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "In Offer",
      prop: "offerProduct"
    }
  }, [_c("el-switch", {
    on: {
      change: _vm.handleOfferSwitch
    },
    model: {
      value: _vm.postForm.offerProduct,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "offerProduct", $$v);
      },
      expression: "postForm.offerProduct"
    }
  })], 1), _vm.postForm.offerProduct ? _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Discount %",
      prop: "discount"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "discount",
      required: "",
      placeholder: "Discount %"
    },
    model: {
      value: _vm.postForm.discount,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "discount", $$v);
      },
      expression: "postForm.discount"
    }
  })], 1) : _vm._e(), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Model No",
      prop: "modelNumber"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "modelNumber",
      required: "",
      placeholder: "Model Number"
    },
    model: {
      value: _vm.postForm.modelNumber,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "modelNumber", $$v);
      },
      expression: "postForm.modelNumber"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Model Name",
      prop: "modelName"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "modelName",
      required: "",
      placeholder: "Model Name"
    },
    model: {
      value: _vm.postForm.modelName,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "modelName", $$v);
      },
      expression: "postForm.modelName"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "MRP",
      prop: "mrp"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "mrp",
      required: "",
      placeholder: "MRP"
    },
    model: {
      value: _vm.postForm.mrp,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "mrp", $$v);
      },
      expression: "postForm.mrp"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Discounted Price",
      prop: "discountedPrice"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      name: "discountedPrice",
      required: "",
      placeholder: "Discounted Price"
    },
    model: {
      value: _vm.postForm.discountedPrice,
      callback: function ($$v) {
        _vm.$set(_vm.postForm, "discountedPrice", $$v);
      },
      expression: "postForm.discountedPrice"
    }
  })], 1), _c("el-form-item", {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      label: "Features and Facilities",
      prop: "featureAndFacilities"
    }
  }, [_c("el-input", {
    staticStyle: {
      width: "50%"
    },
    attrs: {
      placeholder: "Enter a feature and press Enter"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.addFeature.apply(null, arguments);
      }
    },
    model: {
      value: _vm.newFeature,
      callback: function ($$v) {
        _vm.newFeature = $$v;
      },
      expression: "newFeature"
    }
  }), _c("ul", _vm._l(_vm.postForm.featureAndFacilities, function (feature, index) {
    return _c("li", {
      key: index
    }, [_vm._v(" " + _vm._s(feature) + " "), _c("el-button", {
      staticStyle: {
        color: "red",
        "margin-left": "10px"
      },
      attrs: {
        type: "text"
      },
      on: {
        click: function ($event) {
          return _vm.removeFeature(index);
        }
      }
    }, [_c("i", {
      staticClass: "el-icon-delete"
    })])], 1);
  }), 0)], 1), _c("el-form-item", [_c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.loading,
      expression: "loading"
    }, {
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: _vm.submitForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.save")) + " ")]), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    attrs: {
      type: "reset"
    },
    on: {
      click: _vm.resetForm
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("global.form.reset")) + " ")])], 1)], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;