"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "login-container"
  }, [_c("div", {
    staticClass: "boxed"
  }, [_c("el-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("el-col", {
    attrs: {
      sm: 12
    }
  }, [_c("el-form", {
    ref: "loginForm",
    staticClass: "login-form",
    attrs: {
      model: _vm.loginForm,
      rules: _vm.loginRules,
      autocomplete: "on",
      "label-position": "left"
    }
  }, [_c("div", {
    staticClass: "title-container"
  }, [_c("h3", {
    staticClass: "title"
  }, [_c("b", {
    staticStyle: {
      color: "black"
    }
  }, [_vm._v("Login Form")])])]), _c("el-form-item", {
    attrs: {
      prop: "email"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "user"
    }
  })], 1), _c("el-input", {
    ref: "email",
    attrs: {
      placeholder: _vm.$t("login.email"),
      name: "email",
      type: "text",
      autocomplete: "on"
    },
    model: {
      value: _vm.loginForm.email,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "email", $$v);
      },
      expression: "loginForm.email"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "password"
    }
  }, [_c("span", {
    staticClass: "svg-container"
  }, [_c("svg-icon", {
    attrs: {
      name: "password"
    }
  })], 1), _c("el-input", {
    key: _vm.passwordType,
    ref: "password",
    attrs: {
      type: _vm.passwordType,
      placeholder: _vm.$t("login.password"),
      name: "password",
      autocomplete: "on"
    },
    nativeOn: {
      keyup: function ($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.handleLogin.apply(null, arguments);
      }
    },
    model: {
      value: _vm.loginForm.password,
      callback: function ($$v) {
        _vm.$set(_vm.loginForm, "password", $$v);
      },
      expression: "loginForm.password"
    }
  }), _c("span", {
    staticClass: "show-pwd",
    on: {
      click: _vm.showPwd
    }
  }, [_c("svg-icon", {
    attrs: {
      name: _vm.passwordType === "password" ? "eye-off" : "eye-on"
    }
  })], 1)], 1), _c("el-button", {
    staticStyle: {
      width: "100%",
      "margin-bottom": "30px",
      "background-color": "#ffcd5e"
    },
    attrs: {
      loading: _vm.loading,
      type: "primary"
    },
    nativeOn: {
      click: function ($event) {
        $event.preventDefault();
        return _vm.handleLogin.apply(null, arguments);
      }
    }
  }, [_c("b", {
    staticStyle: {
      color: "white"
    }
  }, [_vm._v("LOGIN")])])], 1)], 1), _c("el-col", {
    attrs: {
      sm: 12
    }
  }, [_c("img", {
    staticClass: "login-logo",
    attrs: {
      src: require("@/assets/img/logo2.svg")
    }
  })])], 1)], 1), _c("div")]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;