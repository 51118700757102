"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCart = exports.getCartcount = exports.getCartById = exports.getCart = exports.deleteCart = exports.defaultCartData = exports.checkUserExist = exports.checkEmailExist = exports.addCart = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultCartData = exports.defaultCartData = {
  id: 0,
  enabled: true,
  userId: 0,
  options: JSON,
  productId: 0,
  orderId: 0,
  quantity: 0,
  status: 0
};
const getCart = params => (0, _request.default)({
  url: '/add-to-cart',
  method: 'get',
  params
});
exports.getCart = getCart;
const getCartcount = () => (0, _request.default)({
  url: '/add-to-cart/count',
  method: 'get'
});
exports.getCartcount = getCartcount;
const checkUserExist = data => (0, _request.default)({
  url: '/add-to-cart/username-check',
  method: 'post',
  data
});
exports.checkUserExist = checkUserExist;
const checkEmailExist = data => (0, _request.default)({
  url: '/add-to-cart/email-check',
  method: 'post',
  data
});
exports.checkEmailExist = checkEmailExist;
const getCartById = id => (0, _request.default)({
  url: `/add-to-cart/${id}`,
  method: 'get'
});
exports.getCartById = getCartById;
const updateCart = (id, data) => (0, _request.default)({
  url: `/add-to-cart/${id}`,
  method: 'patch',
  data
});
exports.updateCart = updateCart;
const deleteCart = adminname => (0, _request.default)({
  url: `/add-to-cart/${adminname}`,
  method: 'delete'
});
exports.deleteCart = deleteCart;
const addCart = data => (0, _request.default)({
  url: '/add-to-cart',
  method: 'post',
  data
});
exports.addCart = addCart;