"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-date-picker", {
    staticClass: "filter-item",
    staticStyle: {
      width: "160px"
    },
    attrs: {
      type: "date",
      format: "yyyy-MM-dd",
      name: "taxInvoiceDate",
      placeholder: "From"
    },
    on: {
      change: _vm.fromUtc
    },
    model: {
      value: _vm.listQuery.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "startDate", $$v);
      },
      expression: "listQuery.startDate"
    }
  }), _c("el-date-picker", {
    staticClass: "filter-item",
    staticStyle: {
      width: "160px"
    },
    attrs: {
      type: "date",
      format: "yyyy-MM-dd",
      name: "taxInvoiceDate",
      placeholder: "To"
    },
    on: {
      change: _vm.toUtc
    },
    model: {
      value: _vm.listQuery.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "endDate", $$v);
      },
      expression: "listQuery.endDate"
    }
  }), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")])], 1), _c("el-dialog", {
    attrs: {
      title: "Status",
      visible: _vm.statusDialogVisible,
      width: "30%"
    },
    on: {
      "update:visible": function ($event) {
        _vm.statusDialogVisible = $event;
      }
    }
  }, [_c("span", [_c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Status"
    },
    model: {
      value: _vm.orderData.status,
      callback: function ($$v) {
        _vm.$set(_vm.orderData, "status", $$v);
      },
      expression: "orderData.status"
    }
  }, _vm._l(_vm.statusList, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value,
        disabled: item.disabled
      }
    });
  }), 1), _c("br"), _c("br"), _vm.orderData.status == 5 ? _c("div", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Reason"
    },
    model: {
      value: _vm.orderData.reasonId,
      callback: function ($$v) {
        _vm.$set(_vm.orderData, "reasonId", $$v);
      },
      expression: "orderData.reasonId"
    }
  }, _vm._l(_vm.reasonList, function (item) {
    return _c("el-option", {
      key: item.reason,
      attrs: {
        label: item.reason,
        value: item.id
      }
    });
  }), 1)], 1) : _vm._e(), _c("br"), _c("br"), _vm.orderData.status == 6 || _vm.orderData.status == 7 ? _c("div", [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "Amount",
      required: "",
      placeholder: "Amount"
    },
    model: {
      value: _vm.orderData.transactionid,
      callback: function ($$v) {
        _vm.$set(_vm.orderData, "transactionid", $$v);
      },
      expression: "orderData.transactionid"
    }
  })], 1) : _vm._e(), _c("br"), _c("br"), _vm.orderData.status == 6 || _vm.orderData.status == 7 ? _c("div", [_c("el-select", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      placeholder: "Payment Mode"
    }
  }, [_c("el-option", {
    attrs: {
      label: "CC",
      value: "CC"
    }
  }), _c("el-option", {
    attrs: {
      label: "Cheque",
      value: "Cheque"
    }
  }), _c("el-option", {
    attrs: {
      label: "DD",
      value: "DD"
    }
  })], 1)], 1) : _vm._e(), _vm.orderData.status == 6 || _vm.orderData.status == 7 ? _c("div", [_c("el-input", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      name: "transactionid",
      required: "",
      placeholder: "Transaction id"
    },
    model: {
      value: _vm.orderData.transactionid,
      callback: function ($$v) {
        _vm.$set(_vm.orderData, "transactionid", $$v);
      },
      expression: "orderData.transactionid"
    }
  })], 1) : _vm._e()], 1), _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_c("el-button", {
    on: {
      click: function ($event) {
        _vm.statusDialogVisible = false;
      }
    }
  }, [_vm._v("Cancel")]), _c("el-button", {
    directives: [{
      name: "loading",
      rawName: "v-loading.fullscreen.lock",
      value: _vm.fullscreenLoading,
      expression: "fullscreenLoading",
      modifiers: {
        fullscreen: true,
        lock: true
      }
    }],
    attrs: {
      type: "success"
    },
    on: {
      click: function ($event) {
        return _vm.updateStatus();
      }
    }
  }, [_vm._v("Confirm ")])], 1)]), _c("el-tabs", {
    staticStyle: {},
    attrs: {
      "active-name": _vm.activeTab,
      stretch: true
    },
    on: {
      "tab-click": _vm.handleClick
    }
  }, [_c("el-tab-pane", {
    attrs: {
      name: "newOrders"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("New Orders")]), _c("div", [_vm._v("(Pending)")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Payment Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1), _c("br"), _c("router-link", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            to: "/order/edit/" + scope.row.id
          }
        }, [_c("el-button", {
          staticClass: "filter-item",
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            round: "",
            type: "success",
            size: "mini",
            icon: "el-icon-edit-outline"
          }
        }, [_vm._v(" Edit Order ")])], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v(" Delivery Address ")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          attrs: {
            span: 5
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("div", {
          staticStyle: {
            display: "flex",
            "flex-direction": "row",
            "justify-content": "space-around",
            "flex-wrap": "wrap"
          }
        }, [_c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "success",
            round: ""
          },
          on: {
            click: function ($event) {
              return _vm.updateOrderStatus(scope.row, 1);
            }
          }
        }, [_vm._v(" Recieve ")]), _c("el-dialog", {
          attrs: {
            title: "Reason",
            visible: _vm.reasonDialogVisible,
            width: "30%"
          },
          on: {
            "update:visible": function ($event) {
              _vm.reasonDialogVisible = $event;
            }
          }
        }, [_c("span", [_c("el-select", {
          staticStyle: {
            width: "100%"
          },
          attrs: {
            required: "",
            placeholder: "Select Reason"
          },
          model: {
            value: _vm.orderData.reasonId,
            callback: function ($$v) {
              _vm.$set(_vm.orderData, "reasonId", $$v);
            },
            expression: "orderData.reasonId"
          }
        }, _vm._l(_vm.reasonList, function (item) {
          return _c("el-option", {
            key: item.reason,
            attrs: {
              label: item.reason,
              value: item.id
            }
          });
        }), 1)], 1), _c("span", {
          staticClass: "dialog-footer",
          attrs: {
            slot: "footer"
          },
          slot: "footer"
        }, [_c("el-button", {
          on: {
            click: function ($event) {
              _vm.reasonDialogVisible = false;
            }
          }
        }, [_vm._v("Cancel")]), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          attrs: {
            type: "success"
          },
          on: {
            click: function ($event) {
              return _vm.updateOrderStatus(_vm.orderData, 5);
            }
          }
        }, [_vm._v("Confirm")])], 1)]), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "danger",
            round: ""
          },
          on: {
            click: function ($event) {
              return _vm.RejectOrderStatus(scope.row);
            }
          }
        }, [_vm._v(" Reject ")]), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "warning",
            round: "",
            icon: "el-icon-document"
          }
        }, [_vm._v(" Add to Quickbook ")]), _c("router-link", {
          attrs: {
            to: {
              path: "/product/products-list/",
              query: {
                orderId: scope.row.id,
                userId: scope.row.userId
              }
            }
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          attrs: {
            type: "primary"
          }
        }, [_vm._v(" Modify Order ")])], 1)], 1)]), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    staticClass: "tab-pane",
    attrs: {
      name: "accepted"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Under Reviewed")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 5
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 5
          }
        }, [_c("div", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }, {
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "warning",
            icon: "el-icon-document",
            round: ""
          }
        }, [_vm._v(" Add to Quickbook ")]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Invoice ")])], 1)], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "ready"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Ready to Dispatch")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 5
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Invoice ")])], 1), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "warning",
            round: "",
            icon: "el-icon-document"
          }
        }, [_vm._v(" Add to Quickbook ")])], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "dispatched"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Dispatched")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 5
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 5
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Invoice ")])], 1), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "warning",
            round: "",
            icon: "el-icon-document"
          }
        }, [_vm._v(" Add to Quickbook ")])], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "delivered"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Delivered")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center",
            display: "flex",
            "justify-content": "space-around",
            "vertical-align": "middle",
            "align-items": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 5
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_vm._v(" Invoice ")])], 1), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "warning",
            round: "",
            icon: "el-icon-document"
          }
        }, [_vm._v(" Add to Quickbook ")])], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "paid"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Paid")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center",
            display: "flex",
            "justify-content": "space-around",
            "vertical-align": "middle",
            "align-items": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Transaction Id")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.transactionid))])])], 1)]), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 3
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("br"), _c("br"), _c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_vm._v(" Invoice ")])], 1)], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    attrs: {
      name: "verified"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Verified")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 5
          }
        }, [_c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center",
            display: "flex",
            "justify-content": "space-around",
            "vertical-align": "middle",
            "align-items": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Transaction Id")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.transactionid))])])], 1)]), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 3
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("br"), _c("br"), _c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_vm._v(" Invoice ")])], 1)], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1), _c("el-tab-pane", {
    staticClass: "tab-pane",
    attrs: {
      name: "rejected"
    }
  }, [_c("div", {
    staticClass: "tab-container",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("div", [_vm._v("Rejected")])]), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticStyle: {
      width: "100%"
    },
    attrs: {
      stripe: "",
      data: _vm.list,
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-row", {
          attrs: {
            gutter: 8
          }
        }, [_c("el-col", {
          attrs: {
            span: 5
          }
        }, [_c("div", [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_c("b", [_vm._v("Order Info")])]), _c("div", [_vm._v(" Order ID : # "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.id))])]), _c("div", [_vm._v(" Business Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.businessName))])]), _c("div", [_vm._v(" Customer Name : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.fullName))])]), _c("div", [_vm._v(" Contact : "), _c("span", {
          staticStyle: {
            "font-size": "16px",
            color: "black",
            "font-weight": "500"
          }
        }, [_vm._v(_vm._s(scope.row.user.contactNumber))])])])]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Date and time")])]), _c("el-col", [_c("span", [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "DD-MMM-YYYY hh:mm a")))]), _c("el-popover", {
          attrs: {
            placement: "right",
            width: "500",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.cardDetails
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardHolderName",
            label: "Card Holder Name"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function (scope) {
              return [_vm._v(" " + _vm._s(scope.row.cardHolderName))];
            }
          }], null, true)
        }), _c("el-table-column", {
          attrs: {
            width: "150",
            property: "cardNumber",
            label: "Card Number"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "cvv",
            label: "CVV"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "expireDate",
            label: "Expiry Date"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getCardData(scope.row.userCard);
            }
          },
          slot: "reference"
        }, [_vm._v("Card Details")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600",
            "text-align": "center"
          }
        }, [_vm._v(" Ordered items ")]), _c("div", {
          staticStyle: {
            margin: "auto",
            "text-align": "center"
          }
        }, [_c("el-popover", {
          attrs: {
            placement: "right",
            width: "300",
            trigger: "click"
          }
        }, [_c("el-table", {
          attrs: {
            data: _vm.orderItem
          }
        }, [_c("el-table-column", {
          attrs: {
            width: "150",
            property: "name",
            label: "Product Name"
          }
        }), _c("el-table-column", {
          attrs: {
            width: "100",
            property: "qty",
            label: "Quantity"
          }
        })], 1), _c("el-button", {
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          on: {
            click: function ($event) {
              return _vm.getOrderData(scope.row.id);
            }
          },
          slot: "reference"
        }, [_vm._v("Quick View Order")]), _c("br"), _c("el-button", {
          staticStyle: {
            "margin-top": "10px"
          },
          attrs: {
            slot: "reference",
            icon: "el-icon-view",
            round: "",
            type: "success",
            size: "mini"
          },
          slot: "reference"
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Picklist ")])], 1)], 1)], 1)]), _c("el-col", {
          attrs: {
            span: 3
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Amount")])]), _c("el-col", [scope.row.totalAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Total:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.totalAmount))])]) : _vm._e(), scope.row.discountAmount != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto"
          }
        }, [_c("div", [_vm._v("Discount:-")]), _c("div", [_vm._v(_vm._s("-$ " + scope.row.discountAmount))])]) : _vm._e(), scope.row.grandTotal != null ? _c("div", {
          staticStyle: {
            display: "grid",
            "grid-template-columns": "60% auto",
            "border-top": "1px solid grey"
          }
        }, [_c("div", [_vm._v("Amount To Pay:-")]), _c("div", [_vm._v(_vm._s("$ " + scope.row.grandTotal))])]) : _vm._e()])], 1)]), _c("el-col", {
          attrs: {
            span: 4
          }
        }, [_c("div", [_c("el-col", [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Delivery Address")])]), _c("el-col", [_c("div", [_vm._v(_vm._s(scope.row.userAddresses.address1))]), _c("div", [_vm._v(" " + _vm._s(scope.row.userAddresses.city.name) + ", " + _vm._s(scope.row.userAddresses.pincode) + ", " + _vm._s(scope.row.userAddresses.state.name) + " ")])])], 1)]), scope.row.reasonId != null ? _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 3
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Reason To Reject")])]), _vm._v(" " + _vm._s(scope.row.reason.reason) + " ")]) : _vm._e(), _c("el-col", {
          staticStyle: {
            "text-align": "center"
          },
          attrs: {
            span: 3
          }
        }, [_c("div", {
          staticStyle: {
            "text-align": "center"
          }
        }, [_c("span", {
          staticStyle: {
            color: "#909399",
            "font-weight": "600"
          }
        }, [_vm._v("Action")])]), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          attrs: {
            type: "warning"
          },
          on: {
            click: function ($event) {
              return _vm.OpenStatus(scope.row);
            }
          }
        }, [_vm._v(" Update Status ")]), _c("br"), _c("br"), _c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "warning"
          }
        }, [_c("router-link", {
          attrs: {
            to: "/order/orderPdf/" + scope.row.id
          }
        }, [_vm._v(" Invoice ")])], 1), _c("br"), _c("br"), _c("el-button", {
          directives: [{
            name: "loading",
            rawName: "v-loading.fullscreen.lock",
            value: _vm.fullscreenLoading,
            expression: "fullscreenLoading",
            modifiers: {
              fullscreen: true,
              lock: true
            }
          }],
          staticStyle: {
            margin: "5px"
          },
          attrs: {
            type: "warning",
            round: "",
            icon: "el-icon-document"
          }
        }, [_vm._v(" Add to Quickbook ")])], 1), _c("el-col", [_c("hr", {
          staticStyle: {
            "border-top": "1px solid #bbb"
          }
        })])], 1)];
      }
    }])
  }, [_c("template", {
    slot: "header"
  }, [_c("span", {
    staticStyle: {
      "text-align": "center"
    }
  }, [_vm._v("Orders")])])], 2)], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1)], 1), _c("div", {
    attrs: {
      id: "orderBill",
      hidden: ""
    }
  }, [_c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    staticClass: "table",
    staticStyle: {
      "max-width": "2480px",
      width: "100%"
    },
    attrs: {
      data: _vm.orderList,
      border: "",
      "show-summary": "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    staticStyle: {
      width: "200px"
    },
    attrs: {
      align: "left",
      label: "Description of goods",
      prop: "contact"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.addToCart.product.name)), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    staticStyle: {
      width: "auto",
      overflow: "hidden",
      "word-wrap": "break-word"
    },
    attrs: {
      align: "left",
      label: "Quantity",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.quantity + " ") + " Pcs"), _c("br")];
      }
    }])
  }), _c("el-table-column", {
    staticStyle: {
      width: "auto",
      overflow: "hidden",
      "word-wrap": "break-word"
    },
    attrs: {
      align: "left",
      label: "Rate",
      prop: ""
    }
  }), _c("el-table-column", {
    staticStyle: {
      width: "auto",
      overflow: "hidden",
      "word-wrap": "break-word"
    },
    attrs: {
      align: "left",
      label: "Per",
      prop: ""
    }
  }), _c("el-table-column", {
    staticStyle: {
      width: "auto",
      overflow: "hidden",
      "word-wrap": "break-word"
    },
    attrs: {
      align: "left",
      label: "Amount",
      prop: ""
    }
  })], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;