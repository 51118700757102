"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateOption = exports.getOptions = exports.getOptionInfo = exports.getOptionCount = exports.getOptionById = exports.deleteOption = exports.defaultOptionData = exports.addOption = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultOptionData = exports.defaultOptionData = {
  id: 0,
  enabled: true,
  name: '',
  image: ''
};
const getOptions = params => (0, _request.default)({
  url: '/options',
  method: 'get',
  params
});
exports.getOptions = getOptions;
const getOptionById = id => (0, _request.default)({
  url: `/options/${id}`,
  method: 'get'
});
exports.getOptionById = getOptionById;
const getOptionInfo = data => (0, _request.default)({
  url: '/options/me',
  method: 'post',
  data
});
exports.getOptionInfo = getOptionInfo;
const updateOption = (id, data) => (0, _request.default)({
  url: `/options/${id}`,
  method: 'patch',
  data
});
exports.updateOption = updateOption;
const deleteOption = Optionsname => (0, _request.default)({
  url: `/options/${Optionsname}`,
  method: 'delete'
});
exports.deleteOption = deleteOption;
const addOption = data => (0, _request.default)({
  url: '/options/',
  method: 'post',
  data
});
exports.addOption = addOption;
const getOptionCount = params => (0, _request.default)({
  url: '/options/count',
  method: 'get',
  params
});
exports.getOptionCount = getOptionCount;
const updateOrder = data => (0, _request.default)({
  url: '/options/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;