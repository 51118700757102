"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.error.cause.js");
require("core-js/modules/es.array.push.js");
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _app = require("@/store/modules/app");
var _tagsView = require("@/store/modules/tags-view");
var _router = _interopRequireDefault(require("../../../router"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _gallery = require("@/api/gallery");
var _index = _interopRequireDefault(require("@/components/PanThumb/index.vue"));
var _index2 = _interopRequireDefault(require("@/components/AvatarUpload/index.vue"));
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.showImageUpload = false;
    this.fileList = [];
    this.postForm = Object.assign({}, _gallery.defaultGalleryData);
    this.loading = false;
    this.dialogImageUrl = "";
    this.dialogVisible = false;
    this.disabled = false;
    this.validateImages = this.ImagesValidator;
    this.rules = {
      image: [{
        validator: this.validateImages,
        required: true,
        trigger: "blur"
      }]
    };
  }
  ImagesValidator(rule, value, callback) {
    debugger;
    if (this.postForm.image == "") {
      callback(new Error("Please Upload the Image"));
    }
    return callback();
  }
  handlePictureCardPreview(file) {
    this.dialogImageUrl = file.url;
    this.dialogVisible = true;
  }
  handleRemove(file) {
    this.fileList = [];
    this.postForm.image = "";
  }
  handleChange(field) {
    this.postForm.image = field.response;
  }
  handleExceed(files, fileList) {
    this.$message.warning(`File limit exceeded`);
  }
  get lang() {
    return _app.AppModule.language;
  }
  created() {
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id;
      this.fetchData(parseInt(id));
    } else {
      this.postForm = Object.assign({}, _gallery.defaultGalleryData);
    }
    // Why need to make a copy of this.$route here?
    // Because if you enter this page and quickly switch tag, may be in the execution of this.setTagsViewTitle function, this.$route is no longer pointing to the current page
    // https://github.com/PanJiaChen/vue-element-admin/issues/1221
    this.tempTagView = Object.assign({}, this.$route);
  }
  async fetchData(id) {
    try {
      const data = await (0, _gallery.getGalleryById)(id);
      this.postForm = data;
      this.fileList = [{
        url: data.image
      }];
      // this.fetchValues();
      // Just for test
      const title = "image";
      // Set tagsview title
      this.setTagsViewTitle(title);
      // Set page title
      this.setPageTitle(title);
    } catch (err) {
      Promise.reject(err);
    }
  }
  // private async fetchValues() {
  //   this.redirectionValue = await getCategorys(
  //     getQuery({
  //       enabled: true,
  //       isDeleted: false,
  //       filter: {
  //         enabled: "eq",
  //         isDeleted: "eq",
  //       },
  //     })
  //   );
  // }
  setTagsViewTitle(title) {
    const tagView = this.tempTagView;
    if (tagView) {
      tagView.title = `${title}-${this.postForm.id}`;
      _tagsView.TagsViewModule.updateVisitedView(tagView);
    }
  }
  setPageTitle(title) {
    document.title = `${title} - ${this.postForm.id}`;
  }
  async submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  resetForm() {
    this.$refs.postForm.resetFields();
    this.postForm.image = "";
    this.fileList = [];
  }
  async saveForm() {
    debugger;
    try {
      this.loading = true;
      if (this.isEdit) {
        await (0, _gallery.updateGallery)(this.postForm.id, this.postForm);
      } else {
        await (0, _gallery.addGallery)(this.postForm);
      }
      this.$notify({
        title: "Success",
        message: "Photo saved successfully",
        type: "success",
        duration: 2000
      });
      _router.default.push("/gallery/list");
    } catch (err) {
      this.loading = false;
    }
  }
};
(0, _tslib.__decorate)([(0, _vuePropertyDecorator.Prop)({
  default: false
})], default_1.prototype, "isEdit", void 0);
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "GalleryDetail",
  components: {
    PanThumb: _index.default,
    AvatarUpload: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;