"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
require("echarts/theme/macarons.js");
var _vuePropertyDecorator = require("vue-property-decorator");
var _BarChart = _interopRequireDefault(require("./components/BarChart.vue"));
var _BoxCard = _interopRequireDefault(require("./components/BoxCard.vue"));
var _LineChart = _interopRequireDefault(require("./components/LineChart.vue"));
var _PanelGroup = _interopRequireDefault(require("./components/PanelGroup.vue"));
var _PieChart = _interopRequireDefault(require("./components/PieChart.vue"));
var _RadarChart = _interopRequireDefault(require("./components/RadarChart.vue"));
var _index = _interopRequireDefault(require("./components/TodoList/index.vue"));
// Theme used in BarChart, LineChart, PieChart and RadarChart

const lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.lineChartData = lineChartData.newVisitis;
  }
  handleSetLineChartData(type) {
    this.lineChartData = lineChartData[type];
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'DashboardAdmin',
  components: {
    BarChart: _BarChart.default,
    BoxCard: _BoxCard.default,
    LineChart: _LineChart.default,
    PanelGroup: _PanelGroup.default,
    PieChart: _PieChart.default,
    RadarChart: _RadarChart.default,
    TodoList: _index.default
  }
})], default_1);
var _default = exports.default = default_1;