"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("div", {
    staticClass: "filter-container"
  }, [_c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "160px"
    },
    attrs: {
      placeholder: "Name",
      clearable: ""
    },
    on: {
      input: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.name,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "name", $$v);
      },
      expression: "listQuery.name"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "160px"
    },
    attrs: {
      placeholder: "Feature",
      clearable: ""
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.feature,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "feature", $$v);
      },
      expression: "listQuery.feature"
    }
  }, _vm._l(_vm.visibilityOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.value,
        value: item.key
      }
    });
  }), 1), _c("el-input", {
    staticClass: "filter-item",
    staticStyle: {
      width: "160px"
    },
    attrs: {
      placeholder: "Model Number",
      clearable: ""
    },
    on: {
      input: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.modelNumber,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "modelNumber", $$v);
      },
      expression: "listQuery.modelNumber"
    }
  }), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "140px"
    },
    attrs: {
      placeholder: "New",
      clearable: true
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.new,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "new", $$v);
      },
      expression: "listQuery.new"
    }
  }, _vm._l(_vm.newOption, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("el-select", {
    staticClass: "filter-item",
    staticStyle: {
      width: "140px"
    },
    on: {
      change: _vm.handleFilter
    },
    model: {
      value: _vm.listQuery.sort,
      callback: function ($$v) {
        _vm.$set(_vm.listQuery, "sort", $$v);
      },
      expression: "listQuery.sort"
    }
  }, _vm._l(_vm.sortOptions, function (item) {
    return _c("el-option", {
      key: item.key,
      attrs: {
        label: item.label,
        value: item.key
      }
    });
  }), 1), _c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-search"
    },
    on: {
      click: _vm.handleFilter
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.search")) + " ")]), _vm.listQuery.subCategoryId ? _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/product/add/" + this.listQuery.categoriesId + "/" + this.listQuery.subCategoryId
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" Add Product ")])], 1) : _c("router-link", {
    staticClass: "margin-horizontal",
    attrs: {
      to: "/product/add/" + this.listQuery.categoriesId
    }
  }, [_c("el-button", {
    directives: [{
      name: "waves",
      rawName: "v-waves"
    }],
    staticClass: "filter-item",
    attrs: {
      type: "primary",
      icon: "el-icon-plus"
    }
  }, [_vm._v(" Add Product ")])], 1), _c("el-checkbox", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "15px"
    },
    on: {
      change: function ($event) {
        return _vm.onPriortyChange();
      }
    },
    model: {
      value: _vm.showSortingOption,
      callback: function ($$v) {
        _vm.showSortingOption = $$v;
      },
      expression: "showSortingOption"
    }
  }, [_vm._v(" Change Priority ")]), _c("el-checkbox", {
    staticClass: "filter-item",
    staticStyle: {
      "margin-left": "15px"
    },
    on: {
      change: function ($event) {
        _vm.tableKey = _vm.tableKey + 1;
      }
    },
    model: {
      value: _vm.showCreatedDate,
      callback: function ($$v) {
        _vm.showCreatedDate = $$v;
      },
      expression: "showCreatedDate"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("table.createdDate")) + " ")])], 1), _c("el-table", {
    directives: [{
      name: "loading",
      rawName: "v-loading",
      value: _vm.listLoading,
      expression: "listLoading"
    }],
    key: _vm.tableKey,
    ref: "dragTable",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      data: _vm.list,
      "row-key": "id",
      border: "",
      fit: "",
      "highlight-current-row": ""
    },
    on: {
      "sort-change": _vm.sortChange
    }
  }, [_c("el-table-column", {
    attrs: {
      sortable: "custom",
      width: "100px",
      align: "center",
      label: _vm.$t("table.id"),
      prop: "id",
      "class-name": _vm.getSortClass("id")
    }
  }), _c("el-table-column", {
    attrs: {
      width: "90",
      align: "center",
      label: "Enable/Disable",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("enabled", {
          key: scope.row.id,
          attrs: {
            url: "/products/" + scope.row.id
          },
          model: {
            value: scope.row.enabled,
            callback: function ($$v) {
              _vm.$set(scope.row, "enabled", $$v);
            },
            expression: "scope.row.enabled"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Product Details",
      width: "400"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-avatar", {
          attrs: {
            size: 90,
            src: scope.row.image,
            fit: "fill",
            shape: "square"
          }
        }), _c("br"), scope.row.new == false ? _c("el-badge", [_vm._v(" " + _vm._s(scope.row.name) + " ")]) : _vm._e(), _c("br"), scope.row.new == true ? _c("el-badge", {
          staticClass: "item",
          attrs: {
            value: "new"
          }
        }, [_vm._v(" " + _vm._s(scope.row.name) + " ")]) : _vm._e(), scope.row.description ? _c("span", [_c("br"), _c("br"), _vm._v(_vm._s(scope.row.description) + " ")]) : _vm._e(), _c("br"), _vm._v(" " + _vm._s(scope.row.modelNumber)), _c("br"), _vm._v(" " + _vm._s(scope.row.modelName) + " ")];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Category",
      width: "230"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_vm._v(" " + _vm._s(scope.row.categories.name)), _c("br"), scope.row.subCategory ? _c("span", [_vm._v("↳" + _vm._s(scope.row.subCategory.name))]) : _vm._e()];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      label: "New",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-switch", {
          on: {
            change: function ($event) {
              return _vm.newVisibiltyUpdate(scope.row.id, scope.row.new);
            }
          },
          model: {
            value: scope.row.new,
            callback: function ($$v) {
              _vm.$set(scope.row, "new", $$v);
            },
            expression: "scope.row.new"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      width: "150",
      align: "center",
      label: "Feature",
      prop: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("el-switch", {
          on: {
            change: function ($event) {
              return _vm.visibiltyUpdate(scope.row.id, scope.row.feature);
            }
          },
          model: {
            value: scope.row.feature,
            callback: function ($$v) {
              _vm.$set(scope.row, "feature", $$v);
            },
            expression: "scope.row.feature"
          }
        })];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      align: "center",
      label: "Product Images",
      width: "230"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [scope.row.productImages && scope.row.productImages.length > 0 ? _c("el-carousel", {
          attrs: {
            height: "150px"
          }
        }, _vm._l(scope.row.productImages, function (image) {
          return _c("el-carousel-item", {
            key: image
          }, [_c("el-image", {
            staticStyle: {
              width: "150px",
              height: "150px"
            },
            attrs: {
              src: image,
              "preview-src-list": scope.row.productImages,
              fit: "fit"
            }
          })], 1);
        }), 1) : _vm._e()];
      }
    }])
  }), _vm.showCreatedDate ? _c("el-table-column", {
    attrs: {
      label: _vm.$t("table.createdDate"),
      align: "center"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("span", {
          staticStyle: {
            color: "red"
          }
        }, [_vm._v(_vm._s(_vm._f("moment")(scope.row.createdTimestamp, "from")))])];
      }
    }], null, false, 912526870)
  }) : _vm._e(), _vm.myRole.indexOf("superadmin") > -1 ? _c("el-table-column", {
    attrs: {
      align: "center",
      label: _vm.$t("table.actions"),
      width: "180"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (scope) {
        return [_c("router-link", {
          attrs: {
            to: "/product/edit/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          attrs: {
            type: "warning",
            size: "small",
            icon: "el-icon-edit"
          }
        })], 1), _c("el-button", {
          staticClass: "filter-item",
          staticStyle: {
            "margin-left": "10px"
          },
          attrs: {
            type: "danger",
            size: "small",
            icon: "el-icon-delete"
          },
          on: {
            click: function ($event) {
              return _vm.handleIsDelete(scope.row);
            }
          }
        }), _c("br"), _c("router-link", {
          attrs: {
            to: "/product/options/" + scope.row.id
          }
        }, [_c("el-button", {
          directives: [{
            name: "waves",
            rawName: "v-waves"
          }],
          attrs: {
            type: "primary",
            size: "small"
          }
        }, [_vm._v(" Options ")])], 1)];
      }
    }], null, false, 2243672528)
  }) : _vm._e()], 1), _c("pagination", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.total > 0,
      expression: "total > 0"
    }],
    attrs: {
      total: _vm.total,
      page: _vm.listQuery.page,
      limit: _vm.listQuery.limit
    },
    on: {
      "update:page": function ($event) {
        return _vm.$set(_vm.listQuery, "page", $event);
      },
      "update:limit": function ($event) {
        return _vm.$set(_vm.listQuery, "limit", $event);
      },
      pagination: _vm.getList
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;