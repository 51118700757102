"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const contactRouter = {
  path: '/contact',
  component: _index.default,
  redirect: 'noredirect',
  name: 'contactManagement.title',
  meta: {
    title: 'contactManagement.title',
    icon: 'phone'
  },
  children: [{
    path: 'list',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/contact/list.vue'))),
    name: 'contactManagement.contact.manage',
    meta: {
      title: 'contactManagement.title',
      noCache: true,
      icon: 'phone'
    }
  }]
};
var _default = exports.default = contactRouter;