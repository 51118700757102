"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _admin = require("@/store/modules/admin");
var _index = _interopRequireDefault(require("./admin/index.vue"));
var _index2 = _interopRequireDefault(require("./executive/index.vue"));
let default_1 = class extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.currentRole = 'admin-dashboard';
  }
  get roles() {
    return _admin.AdminModule.roles;
  }
  created() {
    if (this.roles.includes('executive')) {
      this.currentRole = 'executive-dashboard';
    }
  }
};
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: 'Dashboard',
  components: {
    AdminDashboard: _index.default,
    ExecutiveDashboard: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;