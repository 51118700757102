"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateUser = exports.register = exports.logout = exports.login = exports.logPostUser = exports.getUsers = exports.getUserInfo = exports.getUserById = exports.getLogsUser = exports.forgotPassword = exports.deleteUser = exports.defaultUserData = exports.changePasswordLink = exports.changePassword = exports.UpdatePassword = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultUserData = exports.defaultUserData = {
  id: 0,
  enabled: true,
  fullName: '',
  email: '',
  contactNumber: '',
  password: '',
  gender: '',
  avatar: '',
  lastLogin: '',
  adminsRoleId: null,
  createdByFullName: ''
};
const getUsers = params => (0, _request.default)({
  url: '/users',
  method: 'get',
  params
});
exports.getUsers = getUsers;
const getUserById = id => (0, _request.default)({
  url: `/users/${id}`,
  method: 'get'
});
exports.getUserById = getUserById;
const getUserInfo = data => (0, _request.default)({
  url: '/users/me',
  method: 'post',
  data
});
exports.getUserInfo = getUserInfo;
const updateUser = (id, data) => (0, _request.default)({
  url: `/users/${id}`,
  method: 'patch',
  data
});
exports.updateUser = updateUser;
const login = data => (0, _request.default)({
  url: '/users/login',
  method: 'post',
  data
});
exports.login = login;
const forgotPassword = data => (0, _request.default)({
  url: '/users/forgot',
  method: 'post',
  data
});
exports.forgotPassword = forgotPassword;
const changePassword = data => (0, _request.default)({
  url: '/users/reset-password',
  method: 'post',
  data
});
exports.changePassword = changePassword;
const UpdatePassword = data => (0, _request.default)({
  url: '/users/change-password',
  method: 'put',
  data
});
exports.UpdatePassword = UpdatePassword;
const changePasswordLink = data => (0, _request.default)({
  url: '/users/check-link',
  method: 'post',
  data
});
exports.changePasswordLink = changePasswordLink;
const logout = () => (0, _request.default)({
  url: '/users/logout',
  method: 'post'
});
exports.logout = logout;
const register = data => (0, _request.default)({
  url: '/users/',
  method: 'post',
  data
});
exports.register = register;
const logPostUser = data => (0, _request.default)({
  url: '/admin-logs',
  method: 'post',
  data
});
exports.logPostUser = logPostUser;
const getLogsUser = params => (0, _request.default)({
  url: '/admin-logs',
  method: 'get',
  params
});
exports.getLogsUser = getLogsUser;
const deleteUser = id => (0, _request.default)({
  url: `/users/${id}`,
  method: 'delete'
});
exports.deleteUser = deleteUser;