"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateReason = exports.getRoutes = exports.getReasons = exports.getReasonById = exports.deleteReason = exports.defaultReasonData = exports.createReason = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultReasonData = exports.defaultReasonData = {
  id: 0,
  enabled: true,
  reason: ''
};
const getReasons = params => (0, _request.default)({
  url: '/reason',
  method: 'get',
  params
});
exports.getReasons = getReasons;
const getReasonById = id => (0, _request.default)({
  url: `/reason/${id}`,
  method: 'get'
});
exports.getReasonById = getReasonById;
const updateReason = (id, data) => (0, _request.default)({
  url: `/reason/${id}`,
  method: 'patch',
  data
});
exports.updateReason = updateReason;
const deleteReason = id => (0, _request.default)({
  url: `/reason/${id}`,
  method: 'delete'
});
exports.deleteReason = deleteReason;
const createReason = data => (0, _request.default)({
  url: '/reason/',
  method: 'post',
  data
});
exports.createReason = createReason;
const getRoutes = params => (0, _request.default)({
  url: '/routes',
  method: 'get',
  params
});
exports.getRoutes = getRoutes;