"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateOrder = exports.updateOptionValue = exports.getOptionValues = exports.getOptionValueCount = exports.getOptionValueById = exports.deleteOptionValue = exports.defaultOptionValuesData = exports.addOptionValue = void 0;
var _request = _interopRequireDefault(require("@/utils/request"));
const defaultOptionValuesData = exports.defaultOptionValuesData = {
  id: 0,
  enabled: true,
  name: '',
  optionsId: 0
};
const getOptionValues = params => (0, _request.default)({
  url: '/option-values',
  method: 'get',
  params
});
exports.getOptionValues = getOptionValues;
const getOptionValueById = id => (0, _request.default)({
  url: `/option-values/${id}`,
  method: 'get'
});
exports.getOptionValueById = getOptionValueById;
const updateOptionValue = (id, data) => (0, _request.default)({
  url: `/option-values/${id}`,
  method: 'patch',
  data
});
exports.updateOptionValue = updateOptionValue;
const deleteOptionValue = ProductOptionValuesname => (0, _request.default)({
  url: `/option-values/${ProductOptionValuesname}`,
  method: 'delete'
});
exports.deleteOptionValue = deleteOptionValue;
const addOptionValue = data => (0, _request.default)({
  url: '/option-values/',
  method: 'post',
  data
});
exports.addOptionValue = addOptionValue;
const getOptionValueCount = params => (0, _request.default)({
  url: '/option-values/count',
  method: 'get',
  params
});
exports.getOptionValueCount = getOptionValueCount;
const updateOrder = data => (0, _request.default)({
  url: '/option-values/order',
  method: 'post',
  data
});
exports.updateOrder = updateOrder;