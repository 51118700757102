"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _index = _interopRequireDefault(require("@/layout/index.vue"));
const ProductRouter = {
  path: '/product',
  component: _index.default,
  redirect: 'noredirect',
  name: 'ProductManagement.title',
  meta: {
    title: 'ProductManagement.title',
    icon: 'cube',
    hidden: true
  },
  children: [{
    path: 'list/:categoriesId(\\d+)/:subCategoryId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/list.vue'))),
    name: 'ProductManagement.Product.manage',
    meta: {
      title: 'ProductManagement.title',
      noCache: true,
      icon: 'cube'
    }
  }, {
    path: 'list/:categoriesId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/list.vue'))),
    name: 'ProductManagement.Product.manage',
    meta: {
      title: 'ProductManagement.title',
      noCache: true,
      icon: 'cube'
    }
  }, {
    path: 'options/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/components/OptionSelect.vue'))),
    name: 'ProductOptionManagement.Option.manage',
    meta: {
      title: 'ProductOptionManagement.title',
      noCache: true,
      icon: 'cube'
    }
  }, {
    path: 'values/:id(\\d+)/:optionId(\\d+)/:productId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/ValueSelectList.vue'))),
    name: 'ProductOptionManagement.Option.manage',
    meta: {
      title: 'ProductOptionManagement.title',
      noCache: true,
      icon: 'cube'
    }
  }, {
    path: 'values/add/:id(\\d+)/:optionId(\\d+)/:productId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/components/ValueSelect.vue'))),
    name: 'ProductOptionManagement.Option.manage',
    meta: {
      title: 'ProductOptionManagement.title',
      noCache: true,
      icon: 'cube'
    }
  }, {
    path: 'values/edit/:id(\\d+)/:productId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/components/ValueSelect.vue'))),
    name: 'ProductOptionManagement.Option.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'ProductOptionManagement.title',
      noCache: true,
      icon: 'cube'
    }
  }, {
    path: 'edit/:id(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/manage.vue'))),
    name: 'ProductManagement.Product.manage',
    props: {
      isEdit: true
    },
    meta: {
      title: 'ProductManagement.Product.manage',
      noCache: true,
      activeMenu: '/product/list',
      hidden: true
    }
  }, {
    path: 'add/:categoriesId(\\d+)/:subCategoryId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/manage.vue'))),
    name: 'ProductManagement.Product.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'ProductManagement.Product.manage',
      noCache: true,
      hidden: true
    }
  }, {
    path: 'add/:categoriesId(\\d+)',
    component: () => Promise.resolve().then(() => (0, _interopRequireWildcard2.default)(require('@/views/product/manage.vue'))),
    name: 'ProductManagement.Product.manage',
    props: {
      isEdit: false
    },
    meta: {
      title: 'ProductManagement.Product.manage',
      noCache: true,
      hidden: true
    }
  }]
};
var _default = exports.default = ProductRouter;