// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* Variables */\n\n/* Mixins */\n.admin-textarea textarea {\n  padding-right: 40px;\n  resize: none;\n  border: none;\n  border-radius: 0px;\n  border-bottom: 1px solid #bfcbd9;\n}\n.avatar-uploader .el-upload {\n  border: 1px dashed #d9d9d9;\n  border-radius: 6px;\n  cursor: pointer;\n  position: relative;\n  overflow: hidden;\n  width: 248px;\n  height: 148px;\n}\n.avatar-uploader .el-upload:hover {\n  border-color: #0a262b !important;\n}\n.avatar-uploader-icon {\n  font-size: 28px;\n  color: #8c939d;\n  width: 178px;\n  height: 178px;\n  line-height: 178px;\n  text-align: center;\n}\n.el-upload-dragger {\n  border: 0;\n  width: 248px;\n  height: 148px;\n  line-height: 148px;\n}\n.avatar {\n  width: 178px;\n  height: 178px;\n  display: block;\n}\n.el-upload-list__item.is-success div {\n  height: 100%;\n}", ""]);
// Exports
exports.locals = {
	"menuBg": "#333333",
	"menuText": "#fff",
	"menuActiveText": "#333333"
};
module.exports = exports;
