"use strict";

var _interopRequireDefault = require("D:/algoocean/tessco-website-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _tslib = require("tslib");
var _vuePropertyDecorator = require("vue-property-decorator");
var _index = _interopRequireDefault(require("@/components/Pagination/index.vue"));
var _excel = require("@/utils/excel");
var _utils = require("@/utils");
var _index2 = _interopRequireDefault(require("@/components/Enabled/index.vue"));
var _admin = require("@/store/modules/admin");
var _sortablejs = _interopRequireDefault(require("sortablejs"));
var _vuexModuleDecorators = require("vuex-module-decorators");
var _productOptionValues = require("@/api/productOptionValues");
var _product = require("@/api/product");
let default_1 = class default_1 extends _vuePropertyDecorator.Vue {
  constructor() {
    super(...arguments);
    this.tableKey = 0;
    this.total = 0;
    this.optionId = 0;
    this.optionValuesList = [];
    this.postForm = Object.assign({}, _productOptionValues.defaultProductOptionValuesData);
    this.loading = false;
    this.list = [];
    this.myRole = _admin.AdminModule.roles;
    this.listLoading = true;
    this.newList = [];
    this.oldList = [];
    this.disabledDialogVisible = false;
    this.enabledDialogVisible = false;
    this.showSortingOption = false;
    this.renderComponent = true;
    this.downloadLoading = false;
    this.listQuery = {
      page: 1,
      limit: 20,
      sort: "priority,ASC",
      productOptionId: 0,
      "optionValue.name": "",
      // enabled:true,
      filter: {
        enabled: "eq",
        "optionValue.name": "$contL",
        productOptionId: "eq"
      }
    };
    this.showCreatedDate = false;
    this.sortOptions = [{
      label: "Ascending",
      key: "id,ASC"
    }, {
      label: "Descending",
      key: "id,DESC"
    }];
    this.enableTypeOptions = [{
      key: true,
      value: "Enable"
    }, {
      key: false,
      value: "Disabled"
    }];
    this.filters = [];
  }
  disabledDialogOpen(valueId) {
    this.optionValueId = valueId;
    this.disabledDialogVisible = true;
  }
  enabledDialogOpen(valueId) {
    this.optionValueId = valueId;
    this.enabledDialogVisible = true;
  }
  created() {
    this.listQuery.productOptionId = parseInt(this.$route.params && this.$route.params.id);
    this.optionId = parseInt(this.$route.params && this.$route.params.optionId);
    this.productId = parseInt(this.$route.params && this.$route.params.productId);
    this.postForm.productOptionId = this.listQuery.productOptionId;
    this.getList();
  }
  async getList() {
    try {
      this.listLoading = true;
      const data = await (0, _productOptionValues.getProductOptionValues)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      this.productId = parseInt(this.$route.params && this.$route.params.productId);
      this.fetchOptionValues();
    } catch (error) {
      this.listLoading = false;
    }
  }
  async changeToggle(valueId) {
    debugger;
    //  let data1:any = {
    //     productId:Number(this.$route.params.productId)
    //  }
    await (0, _productOptionValues.cartProductOptionValue)({
      productId: Number(this.productId)
    });
    console.log(this.$route.params.productId);
    debugger;
    this.postForm.enabled = false;
    await (0, _productOptionValues.updateProductOptionValue)(valueId, this.postForm).then(res => {
      this.$notify({
        title: "Success",
        message: "Values updated successfully",
        type: "success",
        duration: 2000
      });
    });
    this.disabledDialogVisible = false;
    debugger;
    //  console.log(this.$route.params.productId)
  }
  async changeToggle1(valueId) {
    debugger;
    //  console.log(this.$route.params.productId)
    this.postForm.enabled = true;
    await (0, _productOptionValues.updateProductOptionValue)(valueId, this.postForm).then(res => {
      this.$notify({
        title: "Success",
        message: "Values updated successfully",
        type: "success",
        duration: 2000
      });
    });
    this.enabledDialogVisible = false;
  }
  submitForm() {
    this.$refs.postForm.validate(valid => {
      if (valid) {
        this.saveForm();
      } else {
        return false;
      }
    });
  }
  async saveForm() {
    try {
      this.loading = true;
      // if (this.isEdit) {
      //   await updateOption(this.postForm.id, this.postForm)
      // } else {
      if (this.postForm.optionValueId) {
        await (0, _productOptionValues.addProductOptionValue)(this.postForm).then(res => {
          this.$notify({
            title: "Success",
            message: "Values saved successfully",
            type: "success",
            duration: 2000
          });
          this.postForm.optionValueId = null;
          this.getList();
        });
      } else {
        this.$message.error("Please select an option value");
      }
      // }
    } catch (err) {
      this.loading = false;
    }
  }
  async fetchOptionValues() {
    const data = {
      productOptionId: this.listQuery.productOptionId,
      optionsId: this.optionId
    };
    try {
      const response = await (0, _product.availableValues)(data);
      this.optionValuesList = response.values;
    } catch (err) {
      Promise.reject(err);
    }
  }
  handleFilter() {
    this.listQuery.page = 1;
    this.getList();
  }
  async onPriortyChange() {
    if (this.showSortingOption) {
      this.fetchAllRecored(true);
    } else {
      this.renderComponent = false;
      this.sortable.options.disabled = true;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    }
  }
  async fetchAllRecored(checkForFirstTime) {
    try {
      this.listLoading = true;
      this.listQuery.limit = 1000;
      const data = await (0, _productOptionValues.getProductOptionValues)((0, _utils.getQuery)(this.listQuery));
      this.list = data.data;
      this.total = data.total;
      this.listLoading = false;
      if (checkForFirstTime) {
        this.oldList = this.list.map(v => v.id);
        this.newList = this.oldList.slice();
        this.$nextTick(this.setSort);
      }
    } catch (error) {
      this.listLoading = false;
    }
  }
  setSort() {
    const temp = this.$refs.dragTable;
    const el = temp.$el.querySelectorAll(".el-table__body-wrapper > table > tbody")[0];
    this.sortable = _sortablejs.default.create(el, {
      ghostClass: "sortable-ghost",
      setData: function (dataTransfer) {
        dataTransfer.setData("Text", "");
      },
      onEnd: evt => {
        const targetRow = this.list.splice(Number(evt.oldIndex), 1)[0];
        this.list.splice(Number(evt.newIndex), 0, targetRow);
        const tempIndex = this.newList.splice(evt.oldIndex, 1)[0];
        this.newList.splice(evt.newIndex, 0, tempIndex);
        console.log(this.newList);
        this.updateOrder();
      }
    });
  }
  async updateOrder() {
    try {
      const updateCheck = await (0, _productOptionValues.updateOrder)(this.newList);
      this.fetchAllRecored(false);
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }
  handleDelete(data) {
    if (data) {
      this.$confirm(this.$tc("table.deleteConfirmation", undefined, {
        name: data.name
      }), this.$tc("table.delete"), {
        confirmButtonText: this.$tc("table.delete"),
        confirmButtonClass: "el-button--danger",
        cancelButtonText: "Cancel",
        type: "error"
      }).then(() => {
        (0, _productOptionValues.deleteProductOptionValue)(data.id).then(deleted => {
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: this.$tc("table.deleteCanceled")
        });
      });
    }
  }
  sortChange(data) {
    const {
      prop,
      order
    } = data;
    if (prop) {
      this.listQuery.sort = `${prop},` + (order === "ascending" ? "ASC" : "DESC");
      this.handleFilter();
    }
  }
  getSortClass(key) {
    const sort = this.listQuery.sort;
    return sort === `${key},ASC` ? "ascending" : sort === `${key},DESC` ? "descending" : "";
  }
  handleDownload() {
    this.downloadLoading = true;
    const tHeader = ["ID", "Full Name", "Email", "Contact Number", "Status", "timestamp"];
    const filterVal = ["id", "fullName", "email", "contactNumber", "enabled", "createdTimestamp"];
    const data = (0, _utils.formatJson)(filterVal, this.list);
    (0, _excel.exportJson2Excel)(tHeader, data, this.$tc("route.notifications.title"));
    this.downloadLoading = false;
  }
};
(0, _tslib.__decorate)([_vuexModuleDecorators.Action], default_1.prototype, "saveForm", null);
default_1 = (0, _tslib.__decorate)([(0, _vuePropertyDecorator.Component)({
  name: "TypeList",
  components: {
    Pagination: _index.default,
    Enabled: _index2.default
  }
})], default_1);
var _default = exports.default = default_1;